

import React from 'react';
import { ExternalLink } from 'lucide-react';
import mountainBackground from '../../images/hotels/hotelHome.webp';

const MountainJourneySection = () => {
  return (
    <div className="relative h-screen w-full overflow-hidden">
      <img 
        src={mountainBackground} 
        alt="Stunning mountain landscape" 
        className="absolute inset-0 w-full h-full object-cover"
      />
      
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      
      <div className="relative z-10 h-full flex flex-col justify-center items-center text-white text-center px-4">
        <h1 className="text-5xl md:text-7xl font-extrabold mb-4 tracking-tight">
          Don't Miss Out!
        </h1>
        <p className="text-2xl md:text-4xl font-bold mb-8 max-w-3xl">
          <span className="bg-red-600 px-2 py-1 rounded">Limited Time Offers</span> on Dream Vacations
        </p>
        <p className="text-xl md:text-2xl mb-12 max-w-2xl">
        Exclusive deals on top-rated hotel destinations. Book now and save big!
        </p>
        <a 
          href="https://www.tripadvisor.com" 
          target="_blank" 
          rel="noopener noreferrer"
          className="bg-green-800 hover:bg-green-900 text-white font-bold py-3 px-6 rounded-full text-xl transition duration-300 flex items-center"
        >
          Check Offers 
          <ExternalLink className="ml-2" size={24} />
        </a>
      </div>
    </div>
  );
};

export default MountainJourneySection;