import React from 'react';
import { Search, DollarSign, Calendar } from 'lucide-react';

const About = () => {
  return (
    <section className="bg-gray-100 py-12 px-4">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-6">Why should you make online hotel reservations through themostreviewed?</h2>
        
        <p className="mb-6 text-gray-700">
          Looking for online hotel reservation sites? Your search ends here. themostreviewed is the hotel reservation application you'll turn to, whether for guesthouses or vacation villages, budget-friendly hotels or luxury resorts, for business or pleasure. Our carefully curated and verified list, consisting of more than 400,000 hotels in over 28,000 cities worldwide, provides you with enough options to easily complete your online hotel reservation. Find a list of hotel chains such as Oyo Rooms, FabHotels, Treebo Hotels, etc. Effortlessly separate hotels in New York, Paris, Tokyo, and many more destinations.
        </p>

        <div className="grid md:grid-cols-2 gap-8 mt-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">How to find and book a hotel on themostreviewed?</h3>
            <p className="mb-4">Making an online hotel reservation with themostreviewed has never been this easy.</p>
            <ul className="space-y-2">
              <li className="flex items-center">
                <Search className="w-5 h-5 mr-2 text-blue-500" />
                Click on the 'contact' section on the homepage
              </li>
              <li className="flex items-center">
                <Search className="w-5 h-5 mr-2 text-blue-500" />
                Enter city/locality/landmark/hotel name in the search box
              </li>
              <li className="flex items-center">
                <Calendar className="w-5 h-5 mr-2 text-blue-500" />
                Enter check-in and check-out dates
              </li>
              <li className="flex items-center">
                <Search className="w-5 h-5 mr-2 text-blue-500" />
                Select the number of travelers and press enter
              </li>
            </ul>
            <p className="mt-4">
              That's it! You can further refine your hotel reservation search list using filters such as price, star rating, user rating, amenities, and even hotels with views or couple-friendly hotels. themostreviewed has a hotel for every type of accommodation.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
  <h3 className="text-xl font-semibold mb-4">Discover City Activities</h3>
  <p className="mb-4">
    We've curated a list of the most popular activities in your chosen destination to make your travel experience more memorable and enriching.
  </p>
  <ul className="space-y-2">
    <li className="flex items-center">
      <Search className="w-5 h-5 mr-2 text-blue-500" />
      Explore iconic landmarks, cultural sites, and immerse yourself in local life
    </li>
    <li className="flex items-center">
      <DollarSign className="w-5 h-5 mr-2 text-blue-500" />
      Choose activities that match your budget and book them online in advance
    </li>
    <li className="flex items-center">
      <Calendar className="w-5 h-5 mr-2 text-blue-500" />
      Consider visiting hours and seasonal highlights for the best experience
    </li>
  </ul>
  <p className="mt-4">
    Use our activities guide while planning your journey. themostreviewed has carefully selected the best experiences in each city to help you create unforgettable memories and discover the unique character of your destination.
  </p>
</div>
        </div>
      </div>
    </section>
  );
};

export default About;