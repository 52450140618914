import hotel01 from "../../images/hotels/mexico/hotel01.jpg";
import hotel02 from "../../images/hotels/mexico/hotel02.jpg";
import hotel03 from "../../images/hotels/mexico/hotel03.jpg";
import hotel04 from "../../images/hotels/mexico/hotel04.jpg";
import hotel05 from "../../images/hotels/mexico/hotel05.jpg";
import hotel06 from "../../images/hotels/mexico/hotel06.jpg";
import hotel07 from "../../images/hotels/mexico/hotel07.jpg";
import hotel08 from "../../images/hotels/mexico/hotel08.jpg";
import hotel09 from '../../images/hotels/mexico/hotel09.jpg';

export const mexicoHotels = [
    //1
  {
    name: "Waldorf Astoria Los Cabos Pedregal",
    country: "Mexico",
    imageUrl: hotel01,
    description:
      "Luxurious beachfront resort carved into the cliffs with private plunge pools and ocean views from every room.",
    price: "$1,200/night",
    rating: 4.9,
    link: "https://www.tripadvisor.com/Hotel_Review-g152515-d1200756-Reviews-Waldorf_Astoria_Los_Cabos_Pedregal-Cabo_San_Lucas_Los_Cabos_Baja_California.html",
  },
  //2
  {
    name: "Rosewood Mayakoba",
    imageUrl: hotel02,
    country: "Mexico",
    description:
      "Sophisticated eco-resort set among lagoons and jungle, featuring private boat docks and beach club.",
    price: "$950/night",
    rating: 4.9,
    link: "https://www.tripadvisor.com/Hotel_Review-g150812-d754465-Reviews-Rosewood_Mayakoba-Playa_del_Carmen_Yucatan_Peninsula.html",
  },
  //3
  {
    name: "One&Only Palmilla",
    imageUrl: hotel03,
    country: "Mexico",
    description:
      "Iconic luxury resort offering world-class dining, private beach access, and spectacular Sea of Cortez views.",
    price: "$1,100/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g152516-d185357-Reviews-One_Only_Palmilla-San_Jose_del_Cabo_Los_Cabos_Baja_California.html",
  },
  //4
  {
    name: "Grand Velas Riviera Maya",
    imageUrl: hotel04,
    country: "Mexico",
    description:
      "All-inclusive luxury resort with stunning ocean views, gourmet dining, and world-class spa services.",
    price: "$850/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g150812-d1204526-Reviews-Grand_Velas_Riviera_Maya-Playa_del_Carmen_Yucatan_Peninsula.html",
  },
  //5
  {
    name: "Las Ventanas al Paraíso, A Rosewood Resort",
    imageUrl: hotel05,
    country: "Mexico",
    description:
      "Ultra-luxury resort featuring desert-meets-ocean aesthetics with exceptional service and amenities.",
    price: "$1,300/night",
    rating: 4.9,
    link: "https://www.tripadvisor.com/Hotel_Review-g152516-d270473-Reviews-Las_Ventanas_Al_Paraiso_A_Rosewood_Resort-San_Jose_del_Cabo_Los_Cabos_Baja_California.html",
  },
  //6
  {
    name: "Four Seasons Resort Punta Mita",
    imageUrl: hotel06,
    country: "Mexico",
    description:
      "Beachfront paradise offering world-class golf, private villas, and stunning Pacific Ocean views.",
    price: "$880/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g499443-d153285-Reviews-Four_Seasons_Resort_Punta_Mita-Punta_de_Mita_Pacific_Coast.html",
  },
  //7
  {
    name: "St. Regis Punta Mita Resort",
    imageUrl: hotel07,
    country: "Mexico",
    description:
      "Mediterranean-inspired luxury resort with butler service and three infinity pools overlooking the ocean.",
    price: "$920/night",
    rating: 4.7,
    link: "https://www.tripadvisor.com/Hotel_Review-g499443-d1177214-Reviews-The_St_Regis_Punta_Mita_Resort-Punta_de_Mita_Pacific_Coast.html",
  },
  //8
  {
    name: "NIZUC Resort & Spa",
    imageUrl: hotel08,
    country: "Mexico",
    description:
      "Modern luxury resort combining Mayan heritage with contemporary design, featuring private pools and beach.",
    price: "$750/night",
    rating: 4.7,
    link: "https://www.tripadvisor.com/Hotel_Review-g150807-d3580898-Reviews-Nizuc_Resort_and_Spa-Cancun_Yucatan_Peninsula.html",
  },
  //9
  {
    name: "Banyan Tree Mayakoba",
    imageUrl: hotel09,
    country: "Mexico",
    description:
      "Asian-inspired luxury villas set within a mangrove forest, each with private pool and garden.",
    price: "$890/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g150812-d1218761-Reviews-Banyan_Tree_Mayakoba-Playa_del_Carmen_Yucatan_Peninsula.html",
  },
];