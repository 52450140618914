import React from "react";
import Home from "../Home/Home";
import CookieConsent from "../../components/CookieContest/CookieContest";
import Advice from "../../components/Advice/Advice";
import Faq from "../../components/faq/Faq";
import Gallery from "../../components/Gallery/Gallery";
import Testimonials from "../../components/testimonials/Testimonials";
import CircularStatsCards from "../../components/CircularStatsCards/CircularStatsCards";
import Contact from "../Contact/Contact";
import WhyUs from "../../components/whyUs/WhyUs";
import HotelService from "../../components/hotelService/HotelService";
import About from "../../components/About/About";

export default function Layout() {
  return (
    <div>
      <Home />
      <Gallery />
      <CircularStatsCards />
      <HotelService />
      <CookieConsent />
      <Testimonials />
      <WhyUs />
      <Faq />
      <Contact />
      <About />
      <Advice />
    </div>
  );
}
