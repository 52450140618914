import React, { useState } from 'react';
import { BookOpen, Smartphone, Search, Cloud } from 'lucide-react';

const initialFeatures = [
    {
      icon: BookOpen,
      title: "Convenient Booking",
      description: "Our streamlined booking process allows you to reserve your perfect room in just a few clicks. Enjoy hassle-free planning for your stay.",
      isActive: true
    },
    {
      icon: Smartphone,
      title: "Instant Access",
      description: "Access all hotel services through our user-friendly mobile app. From room service to local recommendations, everything is at your fingertips.",
      isActive: false
    },
    {
      icon: Search,
      title: "Personalized Experience",
      description: "Our AI-powered system learns your preferences to offer tailored suggestions for dining, activities, and amenities, ensuring a unique stay every time.",
      isActive: false
    },
    {
      icon: Cloud,
      title: "Seamless Connectivity",
      description: "Stay connected with high-speed Wi-Fi throughout the property. Work or relax with reliable internet access in your room and all public areas.",
      isActive: false
    }
  ];

const FeatureCard = ({ icon: Icon, title, description, isActive, onClick }) => (
  <div 
    className={`p-6 cursor-pointer transition-colors duration-300 ${isActive ? 'bg-green-600 text-white' : 'bg-white hover:bg-indigo-100'}`}
    onClick={onClick}
  >
    <div className={`w-16 h-16 rounded-full ${isActive ? 'bg-white' : 'bg-green-100'} flex items-center justify-center mb-4`}>
      <Icon className={`w-8 h-8 ${isActive ? 'text-green-600' : 'text-green-600'}`} />
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className={`${isActive ? 'text-indigo-200' : 'text-gray-600'}`}>{description}</p>
    {isActive && (
      <div className="mt-4">
        <span className="text-white">&rarr;</span>
      </div>
    )}
  </div>
);

const FeaturesSection = () => {
  const [features, setFeatures] = useState(initialFeatures);

  const handleCardClick = (clickedIndex) => {
    setFeatures(features.map((feature, index) => ({
      ...feature,
      isActive: index === clickedIndex
    })));
  };

  return (
    <section  className='my-10'>
        <h2 className='text-center text-4xl font-semibold w-full'> Choose us because...</h2>
      <div className="container mx-auto my-10">
        <div className="grid grid-cols-1 md:grid-cols-4">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
              isActive={feature.isActive}
              onClick={() => handleCardClick(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;