import React from 'react';
import HotelCard from './HotelCard';

const HotelSection = ({ cityName, hotels }) => (
  <section className="bg-gray-100 py-16">
    <div className="container mx-auto px-4">
      <h2 className="text-4xl font-bold text-center mb-8">
        Top Hotels in {cityName}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {hotels.map((hotel) => (
          <HotelCard key={hotel.id} hotel={hotel} />
        ))}
      </div>
    </div>
  </section>
);

export default HotelSection;

