import React from 'react';
import { Bed, Utensils, Dumbbell, Waves, Tv, Key } from 'lucide-react';

const services = [
  {
    icon: Bed,
    title: "Comfortable Rooms",
    description: " You want complete comfort in our well-equipped rooms with soft bedding and modern comforts."
  },
  {
    icon: Utensils,
    title: "Elegant Meals",
    description: " Enjoy delicious meals that offer a variety of local and international dishes."
  },
  {
    icon: Dumbbell,
    title: "Fitness Center",
    description: " Stay active in our fitness center, which is open 24/7 for your convenience."
  },
  {
    icon: Waves,
    title: " Swimming Room",
    description: "Cool off in our sad pool or relax by the pool for the perfect rest.."
  },
  {
    icon: Tv,
    title: "TV",
    description: "Enjoy a variety of entertainment facilities, including smart TVs in the bedrooms and entertainment facilities in the public areas."
  },
  {
    icon: Key,
    title: "24/7 Concierge",
    description: "24/7 Concierge Our concierge command is ready to grow through an interrogation and a sutka."
  }
];

const ServiceItem = ({ Icon, title, description }) => (
  <div className="flex flex-col items-start mb-8">
    <Icon className="w-10 h-10 text-yellow-300 mb-4" />
    <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
    <p className="text-gray-300 text-sm">{description}</p>
  </div>
);

const HotelService = () => {
  return (
    <section className="bg-green-700 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-white mb-12">Hotel Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceItem
              key={index}
              Icon={service.icon}
              title={service.title}
              description={service.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HotelService;