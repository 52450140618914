import React from 'react';
import { Star, Clock, Heart, MapPin, ExternalLink } from 'lucide-react';

const ActivityCard = ({ activity, isFavorite, onFavoriteToggle }) => {
  const {
    title,
    description,
    rating,
    duration,
    category,
    price,
    location,
    link
  } = activity;

  const getCategoryColor = (category) => {
    const colors = {
      'Cultural': 'bg-blue-100 text-blue-800',
      'Landmarks': 'bg-purple-100 text-purple-800',
      'Attractions': 'bg-green-100 text-green-800',
      'Food & Shopping': 'bg-orange-100 text-orange-800',
      'Art & Museum': 'bg-pink-100 text-pink-800',
      'Nature': 'bg-emerald-100 text-emerald-800',
      'default': 'bg-gray-100 text-gray-800',
      'Museums': 'bg-green-100 text-green-800',
      'Tours':'bg-red-100 text-red-800'
    };
    return colors[category] || colors.default;
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300">
      <div className="relative">
       
        <button
          onClick={() => onFavoriteToggle(activity.id)}
          className="absolute top-3 right-16 p-2 rounded-full bg-white/80 hover:bg-white transition-colors duration-200 group"
          aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
        >
          <Heart
            className={`w-6 h-6 ${
              isFavorite 
                ? 'fill-red-500 text-red-500' 
                : 'text-gray-600 group-hover:text-red-500'
            }`}
          />
        </button>
        {price && (
          <div className="absolute bottom-4 right-4 bg-white/90 px-3 py-1 rounded-full">
            <span className="font-semibold text-green-700">{price}</span>
          </div>
        )}
      </div>

      {/* Content Section */}
      <div className="p-4">
        {/* Header */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <span className={`text-sm font-medium px-3 py-1 rounded-full ${getCategoryColor(category)}`}>
              {category}
            </span>
            <div className="flex items-center">
              <Star className="w-5 h-5 text-yellow-400 fill-yellow-400" />
              <span className="ml-1 font-medium">{rating.toFixed(1)}</span>
            </div>
          </div>
          <h3 className="text-xl font-bold text-gray-900">{title}</h3>
        </div>

        {/* Description */}
        <p className="mt-3 text-gray-600 line-clamp-3">
          {description}
        </p>

        {/* Details */}
        <div className="mt-4 space-y-2">
          {location && (
            <div className="flex items-center text-gray-600">
              <MapPin className="w-4 h-4 mr-2" />
              <span className="text-sm">{location}</span>
            </div>
          )}
          <div className="flex items-center text-gray-600">
            <Clock className="w-4 h-4 mr-2" />
            <span className="text-sm">{duration}</span>
          </div>
        </div>

        {/* Actions */}
        <div className="mt-4 pt-4 border-t border-gray-100 flex justify-between items-center">
          <button 
            onClick={() => window.open(link, '_blank')}
            className="flex items-center text-blue-600 hover:text-blue-700 transition-colors"
          >
            <ExternalLink className="w-4 h-4 mr-1" />
            <span className="text-sm font-medium">View Details</span>
          </button>
          <button className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"  onClick={() => window.open(link, '_blank')}>
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

// Props validation
ActivityCard.defaultProps = {
  isFavorite: false,
  onFavoriteToggle: () => {},
};

export default ActivityCard;
