import { japanActivities } from "./japan";
import { turkeyActivities } from "./turkey";
import { franceActivities } from "./france";
import { mexicoActivities } from "./mexico";

export const allActivities = [
  ...japanActivities,
  ...turkeyActivities,
  ...franceActivities,
  ...mexicoActivities,
];
