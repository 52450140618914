import React from 'react';
import background from '../../images/hotels/hotel00.jpg'

const SpaTreatmentsSection = () => {
  return (
    <section className="relative h-[400px] overflow-hidden ">
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${background})`,
          filter: 'brightness(0.7)',
          height: "400px",
          width: "100%",
        
        }}
      ></div>
      
      <div className="relative h-full flex flex-col justify-center items-start p-8 md:p-16 text-white z-10">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">Luxe Stay</h1>
        <p className="text-xl md:text-2xl mb-8 max-w-2xl">
        Discover unparalleled comfort and elegance in our carefully curated selection of hotels. 
          From cozy boutique inns to lavish resorts, find your perfect stay for an unforgettable experience.
        </p>
       
      </div>

    
    </section>
  );
};

export default SpaTreatmentsSection;