import React, { useState } from 'react';
import ActivityCard from './ActivityCard';

const ActivitySection = ({ cityName, activities }) => {
  const [favorites, setFavorites] = useState(new Set());

  const toggleFavorite = (id) => {
    setFavorites(prev => {
      const newFavorites = new Set(prev);
      if (newFavorites.has(id)) {
        newFavorites.delete(id);
      } else {
        newFavorites.add(id);
      }
      return newFavorites;
    });
  };

  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8">
          Things to Do in {cityName}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {activities.map((activity) => (
            <ActivityCard
              key={activity.id}
              activity={activity}
              isFavorite={favorites.has(activity.id)}
              onFavoriteToggle={() => toggleFavorite(activity.id)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ActivitySection;