import logo01 from '../../images/agoda0.png'
import logo02 from '../../images/booking.webp'
import logo03 from '../../images/tripad.webp'
import logo04 from '../../images//trivago.svg'

const logos = [
  { name: 'Blue Lake', image: logo01 },
  { name: 'Life Adventure', image: logo02 },
  { name: 'Mountain Extreme', image: logo03 },
  { name: 'Life Adventure 2', image: logo04 },
]

const LogoItem = ({ name, image }) => (
  <div className="w-1/2 sm:w-1/4 md:w-1/4 lg:w-1/8 p-4">
    <div className="relative group">
      <img 
        src={image} 
        alt={name}
        className="w-full h-auto transition-transform duration-500 ease-in-out transform group-hover:rotate-y-180"
      />
      <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
        <span className="text-gray-800 font-semibold text-center">{name}</span>
      </div>
    </div>
  </div>
);

const Advice = () => {
  return (
    <section className="py-16 px-4 ">
      <div className="max-w-6xl mx-auto">
      
        <div className="flex flex-wrap -mx-4">
          {logos.map((logo, index) => (
            <LogoItem key={index} {...logo} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Advice;