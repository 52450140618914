import React, { useState } from "react";
import { Menu, X, ChevronDown } from "lucide-react";
import { NavLink } from "react-router-dom";

const menuItems = [
  { name: "Home", path: "/" },
  { name: "About ", path: "/about-us" },
  {
    name: "Destinations",
    path: "/destinations",
    isDropdown: true,
    dropdownItems: [
      { name: "Japan", path: "/japan" },
      { name: "France", path: "/france" },
      { name: "Mexico", path: "/mexico" },
      { name: "Turkey", path: "/turkey" },
    ],
  },
  { name: "Services", path: "/services" },
  { name: "FAQ", path: "/faq" },
  { name: "Contact ", path: "/contact-us" },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownClick = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  // Yeni: Dropdown veya bir link seçildiğinde menüleri kapatma fonksiyonu
  const closeMenus = () => {
    setIsOpen(false);
    setActiveDropdown(null);
  };

  const DropdownMenu = ({ items }) => (
    <div className="absolute top-full left-0 w-48 bg-white rounded-md shadow-lg py-1 mt-1">
      {items.map((item) => (
        <NavLink
          key={item.name}
          to={item.path}
          className={({ isActive }) =>
            `block px-4 py-2 text-gray-900 text-sm hover:bg-slate-100 transition-colors duration-200 ${
              isActive && "bg-slate-100 font-semibold"
            }`
          }
          onClick={closeMenus} 
        >
          {item.name}
        </NavLink>
      ))}
    </div>
  );

  const MenuItem = ({ item }) => {
    if (item.isDropdown) {
      return (
        <div className="relative">
          <button
            onClick={() => handleDropdownClick(item.name)}
            className={`text-gray-900 px-3 py-2 rounded-md text-md font-bold flex items-center hover:bg-white hover:text-gray-900 transition-colors duration-200 ${
              activeDropdown === item.name ? "bg-white" : ""
            }`}
          >
            {item.name}
            <ChevronDown className="inline-block ml-1 h-4 w-4" />
          </button>
          {activeDropdown === item.name && (
            <DropdownMenu items={item.dropdownItems} />
          )}
        </div>
      );
    }

    return (
      <NavLink
        to={item.path}
        className={({ isActive }) =>
          `text-gray-900 hover:bg-white hover:text-gray-900 px-1 py-2 rounded-md text-md font-bold flex items-center transition-colors duration-200 ${
            isActive ? "bg-white" : ""
          }`
        }
        onClick={closeMenus}
      >
        {item.name}
      </NavLink>
    );
  };

  const MobileMenuItem = ({ item }) => {
    if (item.isDropdown) {
      return (
        <div>
          <button
            onClick={() => handleDropdownClick(`mobile-${item.name}`)}
            className={`w-full text-left px-3 py-2 rounded-md text-lg font-bold flex items-center justify-between text-gray-900 hover:bg-white transition-colors duration-200 ${
              activeDropdown === `mobile-${item.name}` ? "bg-white" : ""
            }`}
          >
            {item.name}
            <ChevronDown className="h-4 w-4" />
          </button>
          {activeDropdown === `mobile-${item.name}` && (
            <div className="pl-4 bg-white rounded-md mt-1">
              {item.dropdownItems.map((dropdownItem) => (
                <NavLink
                  key={dropdownItem.name}
                  to={dropdownItem.path}
                  className={({ isActive }) =>
                    `block px-3 py-2 rounded-md text-md text-gray-900 hover:bg-slate-100 transition-colors duration-200 ${
                      isActive && "bg-slate-100 font-semibold"
                    }`
                  }
                  onClick={closeMenus} 
                >
                  {dropdownItem.name}
                </NavLink>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <NavLink
        to={item.path}
        className={({ isActive }) =>
          `px-3 py-2 rounded-md text-lg font-bold flex items-center text-gray-900 hover:bg-white transition-colors duration-200 ${
            isActive && "bg-white"
          }`
        }
        onClick={closeMenus} 
      >
        {item.name}
      </NavLink>
    );
  };

  return (
    <nav className="backdrop-blur-sm absolute top-0 left-0 w-full z-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <NavLink to="/" className="ml-2 font-bold text-3xl text-green-600 underline line-clamp-5 no-underline">
                The most reviewed
              </NavLink>
            </div>
          </div>

          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-4 ">
              {menuItems.map((item) => (
                <MenuItem key={item.name} item={item} />
              ))}
            </div>
          </div>

          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-900 hover:bg-white focus:outline-none"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <X className="block h-6 w-6" />
              ) : (
                <Menu className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden bg-slate-100/90 backdrop-blur-sm">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {menuItems.map((item) => (
              <MobileMenuItem key={item.name} item={item} />
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;
