export const franceActivities = [
    {
      id: 1,
      title: "Eiffel Tower",
      description: "Iconic iron lattice tower offering stunning city views, restaurants, and a unique architectural experience. Best visited during sunset or for the hourly night sparkle show.",
      rating: 4.8,
      duration: "2-3 hours",
      category: "Landmarks",
      country: "France",
      price: "$30-75",
      link: "https://www.tripadvisor.com/Attraction_Review-g187147-d188151-Reviews-Eiffel_Tower-Paris_Ile_de_France.html",
      location: "Champ de Mars, 5 Avenue Anatole France, 75007 Paris"
    },
    {
      id: 2,
      title: "Louvre Museum",
      description: "World's largest art museum, home to thousands of works of art including the Mona Lisa, Venus de Milo, and Winged Victory. Former royal palace with stunning architecture.",
      rating: 4.7,
      duration: "3-4 hours",
      category: "Museums",
      country: "France",
      price: "$22",
      link: "https://www.tripadvisor.com/Attraction_Review-g187147-d188757-Reviews-Louvre_Museum-Paris_Ile_de_France.html",
      location: "Rue de Rivoli, 75001 Paris"
    },
    {
      id: 3,
      title: "Palace of Versailles",
      description: "Opulent royal château with stunning Hall of Mirrors, State Apartments, and magnificent gardens with fountains. Former residence of Louis XIV and Marie Antoinette.",
      rating: 4.8,
      duration: "4-6 hours",
      category: "Historical",
      country: "France",
      price: "$20-27",
      link: "https://www.tripadvisor.com/Attraction_Review-g187148-d188681-Reviews-Palace_of_Versailles-Versailles_Yvelines_Ile_de_France.html",
      location: "Place d'Armes, 78000 Versailles"
    },
    {
      id: 4,
      title: "Arc de Triomphe",
      description: "Iconic triumphal arch honoring those who fought for France, offering panoramic views of the Champs-Élysées and Paris from its viewing platform.",
      rating: 4.7,
      duration: "1-2 hours",
      category: "Landmarks",
      country: "France",
      price: "$15",
      link: "https://www.tripadvisor.com/Attraction_Review-g187147-d188709-Reviews-Arc_de_Triomphe-Paris_Ile_de_France.html",
      location: "Place Charles de Gaulle, 75008 Paris"
    },
    {
      id: 5,
      title: "Notre-Dame Cathedral",
      description: "Medieval Catholic cathedral known for its French Gothic architecture, currently under restoration after 2019 fire. External viewing and visitor center available.",
      rating: 4.6,
      duration: "1 hour",
      category: "Religious",
      country: "France",
      price: "Free",
      link: "https://www.tripadvisor.com/Attraction_Review-g187147-d188679-Reviews-Cathedrale_Notre_Dame_de_Paris-Paris_Ile_de_France.html",
      location: "6 Parvis Notre-Dame - Pl. Jean-Paul II, 75004 Paris"
    },
    {
      id: 6,
      title: "Seine River Cruise",
      description: "Scenic boat tour along the Seine River offering views of Paris's main monuments. Available as day tours, dinner cruises, or romantic evening cruises.",
      rating: 4.6,
      duration: "1-3 hours",
      category: "Tours",
      country: "France",
      price: "$20-85",
      link: "https://www.tripadvisor.com/Attraction_Review-g187147-d592716-Reviews-Seine_River-Paris_Ile_de_France.html",
      location: "Various departure points along Seine River, Paris"
    },
    {
      id: 7,
      title: "Musée d'Orsay",
      description: "Impressive art museum housed in former railway station, featuring the world's largest collection of Impressionist masterpieces and Art Nouveau architecture.",
      rating: 4.8,
      duration: "2-3 hours",
      category: "Museums",
      country: "France",
      price: "$18",
      link: "https://www.tripadvisor.com/Attraction_Review-g187147-d188150-Reviews-Musee_d_Orsay-Paris_Ile_de_France.html",
      location: "1 Rue de la Légion d'Honneur, 75007 Paris"
    },
    {
      id: 8,
      title: "Sacré-Cœur",
      description: "Stunning white basilica atop Montmartre hill offering panoramic city views. Surrounded by charming artistic neighborhood and street artists.",
      rating: 4.7,
      duration: "1-2 hours",
      category: "Religious",
      country: "France",
      price: "Free",
      link: "https://www.tripadvisor.com/Attraction_Review-g187147-d190686-Reviews-Basilique_du_Sacre_Coeur_de_Montmartre-Paris_Ile_de_France.html",
      location: "35 Rue du Chevalier de la Barre, 75018 Paris"
    },
    {
      id: 9,
      title: "Champs-Élysées Shopping",
      description: "World-famous avenue featuring luxury boutiques, restaurants, and theatres. Stretches from Place de la Concorde to Arc de Triomphe.",
      rating: 4.5,
      duration: "2-4 hours",
      category: "Shopping",
      country: "France",
      price: "Free to walk",
      link: "https://www.tripadvisor.com/AttractionProductReview-g187147-d11979679-Bateaux_Parisiens_Seine_River_Gourmet_Lunch_Sightseeing_Cruise-Paris_Ile_de_France.html",
      location: "Avenue des Champs-Élysées, 75008 Paris"
    }
  ];