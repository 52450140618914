import React, { useState } from "react";
import { Phone, Mail, MapPin, Globe } from "lucide-react";

const ContactSection = () => {
  const [activeTab, setActiveTab] = useState("contact");
  const [isMessageSent, setIsMessageSent] = useState(false);

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-green-700 mb-8 text-center">
          Get in Touch
        </h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          Whether you have a question about our services, need help with a
          booking, or just want to say hello, we're here to help. Choose an
          option below to get started.
        </p>
        <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-12">
          <div className="flex border-b">
            <TabButton
              active={activeTab === "contact"}
              onClick={() => setActiveTab("contact")}
            >
              Contact Us
            </TabButton>
            <TabButton
              active={activeTab === "book"}
              onClick={() => setActiveTab("book")}
            >
              Book a Room
            </TabButton>
          </div>
          <div className="p-6 mt-4">
            {activeTab === "contact" ? (
              <ContactForm setIsMessageSent={setIsMessageSent} />
            ) : (
              <BookingForm />
            )}
          </div>
        </div>
        {isMessageSent && (
          <div
            className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-8"
            role="alert"
          >
            <p className="font-bold">Message Sent!</p>
            <p>Thank you for contacting us. We'll get back to you shortly.</p>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-green-700 font-bold">
          <ContactInfo
            icon={Phone}
            info="               +353 1 902 0091
            "
            subInfo="24/7 Customer Support"
          />
          <ContactInfo
            icon={Mail}
            info="support@themostreviewed.com"
            subInfo="We reply within 24 hours"
          />
          <ContactInfo
            icon={MapPin}
            info="13 Northbrook Road, Dublin 6, Ireland"
            subInfo="Visit our main office"
          />
        </div>
      </div>
    </section>
  );
};

const TabButton = ({ children, active, onClick }) => (
  <button
    className={`flex-1 py-3 px-4 text-center font-semibold ${
      active
        ? "bg-green-700 text-white"
        : "bg-gray-100 text-green-700 hover:bg-gray-200"
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);

const ContactForm = ({ setIsMessageSent }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to a server
    setIsMessageSent(true);
    e.target.reset(); // Reset form fields
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        className="w-full p-2 border rounded"
        type="text"
        placeholder="Your Name"
        required
      />
      <input
        className="w-full p-2 border rounded"
        type="email"
        placeholder="Your Email"
        required
      />
      <select className="w-full p-2 border rounded" required>
        <option value="">Select a Topic</option>
        <option value="booking">Booking Inquiry</option>
        <option value="support">Customer Support</option>
        <option value="feedback">Feedback</option>
        <option value="other">Other</option>
      </select>
      <textarea
        className="w-full p-2 border rounded"
        rows="4"
        placeholder="Your Message"
        required
      ></textarea>
      <div className="flex items-center">
        <input type="checkbox" id="privacy" className="mr-2" required />
        <label htmlFor="privacy" className="text-sm text-gray-600">
          I agree to the privacy policy
        </label>
      </div>
      <button className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-800 transition duration-300">
        Send Message
      </button>
    </form>
  );
};

const BookingForm = () => {
  const handleBooking = (e) => {
    e.preventDefault();
    const destination = e.target.elements.destination.value;

    const tripAdvisorUrl = `https://www.tripadvisor.com/Search?q=${encodeURIComponent(destination)}`;

    window.open(tripAdvisorUrl, "_blank");
  };

  return (
    <form onSubmit={handleBooking} className="space-y-4">
      <input
        name="destination"
        className="w-full p-2 border rounded"
        type="text"
        placeholder="Destination"
        required
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <input className="w-full p-2 border rounded" type="date" required />
        <input className="w-full p-2 border rounded" type="date" required />
      </div>
      <input
        className="w-full p-2 border rounded"
        type="number"
        min="1"
        placeholder="Number of Guests"
        required
      />
      <button className="w-full bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-800 transition duration-300">
        Search on TripAdvisor
      </button>
    </form>
  );
};

const ContactInfo = ({ icon: Icon, info, subInfo }) => (
  <div className="flex items-center">
    <Icon className="w-8 h-8 text-yellow-300 mr-4" />
    <div>
      <p className="font-bold">{info}</p>
      <p className="text-sm text-gray-600">{subInfo}</p>
    </div>
  </div>
);

export default ContactSection;
