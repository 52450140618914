import hotel01 from "../../images/hotels/tokio/hotel01.jpg";
import hotel02 from "../../images/hotels/tokio/hotel02.jpg";
import hotel03 from "../../images/hotels/tokio/hotel03.jpg";
import hotel04 from "../../images/hotels/tokio/hotel04.jpg";
import hotel05 from "../../images/hotels/tokio/hotel05.jpg";
import hotel06 from "../../images/hotels/tokio/tokio06.jpg";
import hotel07 from "../../images/hotels/tokio/hotel07.jpg";
import hotel08 from "../../images/hotels/tokio/hotel08.jpg";
import hotel09 from '../../images/hotels/tokio/hotel09.jpg';

export const japanHotels = [
  {
    name: "Park Hyatt Tokyo",
    country: "Japan",
    imageUrl: hotel01,
    description:
      "Luxury hotel featured in 'Lost in Translation', offering stunning city views.",
    price: "$467/night",
    rating: 4.7,
    link: "https://www.tripadvisor.com/Hotel_Review-g14133673-d307368-Reviews-Park_Hyatt_Tokyo-Nishishinjuku_Shinjuku_Tokyo_Tokyo_Prefecture_Kanto.html",
  },
  {
    name: "The Ritz-Carlton Tokyo",
    imageUrl: hotel02,
    country: "Japan",
    description:
      "Opulent hotel occupying the top floors of a 53-story tower in Roppongi.",
    price: "$533/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g14129743-d634263-Reviews-The_Ritz_carlton_Tokyo-Akasaka_Minato_Tokyo_Tokyo_Prefecture_Kanto.html",
  },
  {
    name: "Aman Tokyo",
    imageUrl: hotel03,
    country: "Japan",
    description:
      "Urban sanctuary with a serene atmosphere and traditional Japanese aesthetics.",
    price: "$600/night",
    rating: 4.9,
    link: "https://www.tripadvisor.com/Hotel_Review-g14129477-d7371377-Reviews-Aman_Tokyo-Otemachi_Chiyoda_Tokyo_Tokyo_Prefecture_Kanto.html",
  },
  {
    name: "Mandarin Oriental Tokyo",
    imageUrl: hotel04,
    country: "Japan",
    description:
      "Sleek, contemporary hotel with panoramic views and Michelin-starred dining.",
    price: "$500/night",
    rating: 4.7,
    link: "https://www.tripadvisor.com/Hotel_Review-g1066444-d585204-Reviews-Mandarin_Oriental_Tokyo-Chuo_Tokyo_Tokyo_Prefecture_Kanto.html",
  },
  {
    name: "Four Seasons Hotel Tokyo at Marunouchi",
    imageUrl: hotel05,
    country: "Japan",
    description:
      "Boutique luxury hotel offering personalized service in a central location.",
    price: "$467/night",
    rating: 4.6,
    link: "https://www.tripadvisor.com/Hotel_Review-g14129528-d301911-Reviews-Four_Seasons_Hotel_Tokyo_At_Marunouchi-Marunouchi_Chiyoda_Tokyo_Tokyo_Prefecture_Kant.html",
  },
  {
    name: "The Peninsula Tokyo",
    imageUrl: hotel06,
    country: "Japan",
    description:
      "Iconic hotel blending traditional Japanese hospitality with modern luxury.",
    price: "$567/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g14129536-d580687-Reviews-The_Peninsula_Tokyo-Yurakucho_Chiyoda_Tokyo_Tokyo_Prefecture_Kanto.html",
  },
  {
    name: "Andaz Tokyo Toranomon Hills",
    imageUrl: hotel07,
    country: "Japan",
    description:
      "Contemporary lifestyle hotel with artistic touches and skyline views.",
    price: "$433/night",
    rating: 4.6,
    link: "https://www.tripadvisor.com/Hotel_Review-g14129647-d6485175-Reviews-Andaz_Tokyo_Toranomon_Hills-Toranomon_Minato_Tokyo_Tokyo_Prefecture_Kanto.html",
  },
  {
    name: "Conrad Tokyo",
    imageUrl: hotel08,
    country: "Japan",
    description:
      "Modern luxury hotel in the heart of the Shiodome business district.",
    price: "$400/night",
    rating: 4.5,
    link: "https://www.tripadvisor.com/Hotel_Review-g1066451-d571809-Reviews-Conrad_Tokyo-Minato_Tokyo_Tokyo_Prefecture_Kanto.html",
  },
  {
    name: "The Capitol Hotel Tokyu",
    imageUrl: hotel09,
    country: "Japan",
    description:
      "Elegant hotel with a rich history, offering a blend of tradition and modernity.",
    price: "$367/night",
    rating: 4.5,
    link: "https://www.tripadvisor.com/Hotel_Review-g1066443-d1832457-Reviews-The_Capitol_Hotel_Tokyu-Chiyoda_Tokyo_Tokyo_Prefecture_Kanto.html",
  },
];