import React from "react";
import Testimonials from "../../components/testimonials/Testimonials";
import HotelService from "../../components/hotelService/HotelService";
import image from "../../images/hotels/download.webp";

const CompanyOverviewV2 = () => (
  <div className="bg-white my-20">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-12 text-center text-green-700">
        About themostreviewed
      </h2>
      <div className="flex flex-wrap -mx-4 items-center">
        <div className="w-full md:w-1/2 px-4">
          <p className="text-gray-700 mb-4">
            themostreviewed stands at the forefront of the hotel booking industry,
            redefining the way travelers experience accommodations worldwide. As
            a premier platform, we've made it our mission to curate and offer
            exceptional stays that cater to every traveler's unique preferences
            and desires.
          </p>

          <p className="text-gray-700 mb-4">
            At the heart of themostreviewed's philosophy is the belief that the right
            lodging is not just a place to rest, but a integral part of the
            travel experience. We understand that each journey is unique, and so
            are the needs of our diverse clientele. Whether you're seeking the
            intimate charm of a boutique hotel tucked away in a historic city
            center, the opulent luxury of a five-star resort on a pristine
            beach, or the authentic experience of a locally-run guesthouse in a
            quaint village, themostreviewed has the perfect option for you.
          </p>
          <p className="text-gray-700 mb-4">
            Our commitment to excellence is reflected in our meticulously
            curated selection of over 100,000 properties spanning 190 countries.
            This vast yet carefully chosen array ensures that whether you're
            planning a business trip, a romantic getaway, a family vacation, or
            a solo adventure, you'll find accommodations that not only meet but
            exceed your expectations.
          </p>
          <p className="text-gray-700">
            What sets themostreviewed apart is not just the quantity of our offerings,
            but the quality and diversity. We've partnered with the best in the
            hospitality industry - from internationally renowned hotel chains to
            hidden gems known only to locals. Our team of travel experts
            personally vets each property, ensuring that it meets our stringent
            standards of comfort, service, and authenticity. With themostreviewed,
            you're not just booking a room; you're unlocking the door to a world
            of carefully crafted experiences, each promising to transform your
            good trip into an extraordinary journey.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0 order-1 md:order-2">
          <img
            src={image}
            alt="themostreviewed Office"
            className="rounded-lg shadow-lg w-full h-auto"
          />
        </div>
      </div>
    </div>
  </div>
);

// Main About Component
const About = () => (
  <div>
    <CompanyOverviewV2 />
    <HotelService />
    <Testimonials />
  </div>
);

export default About;
