import React, { useState } from 'react';
import { Search, ChevronDown, ChevronUp, CreditCard, Plane, Hotel, Globe, Shield, Clock } from 'lucide-react';

const FAQPage = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedItems, setExpandedItems] = useState(new Set());

  const categories = [
    { id: 'all', name: 'All Questions', icon: Globe },
    { id: 'booking', name: 'Booking', icon: Hotel },
    { id: 'payment', name: 'Payment', icon: CreditCard },
    { id: 'travel', name: 'Travel', icon: Plane },
    { id: 'security', name: 'Security', icon: Shield },
    { id: 'cancellation', name: 'Cancellation', icon: Clock },
  ];

  const faqData = [
    {
      category: 'booking',
      question: 'How do I make a hotel reservation?',
      answer: 'Making a hotel reservation is easy! Simply search for your destination, select your dates and preferences, choose your hotel, and complete the booking process. You will receive an immediate confirmation email with all the details.'
    },
    {
      category: 'booking',
      question: 'Can I modify my booking after confirmation?',
      answer: 'Yes, you can modify your booking through your account dashboard. However, changes are subject to availability and may incur additional charges depending on the hotels policy.'
    },
    {
      category: 'payment',
      question: 'What payment methods do you accept?',
      answer: 'We accept all major credit cards (Visa, MasterCard, American Express), PayPal, and in some regions, we offer local payment options. All payments are processed securely.'
    },
    {
      category: 'payment',
      question: 'Is it safe to enter my credit card information?',
      answer: 'Yes, we use industry-standard SSL encryption to protect your payment information. Your credit card details are never stored on our servers.'
    },
    {
      category: 'travel',
      question: 'Do I need travel insurance?',
      answer: 'While travel insurance is not mandatory, we highly recommend it to protect against unexpected events. We offer comprehensive travel insurance packages that cover medical emergencies, trip cancellations, and lost luggage.'
    },
    {
      category: 'travel',
      question: 'What documents do I need for international travel?',
      answer: 'For international travel, you typically need a valid passport with at least 6 months validity, relevant visas, and any required health documentation. Specific requirements vary by destination.'
    },
    {
      category: 'security',
      question: 'How do you protect my personal information?',
      answer: 'We employ state-of-the-art security measures to protect your personal data. This includes encryption, secure servers, and regular security audits. We never share your information with unauthorized parties.'
    },
    {
      category: 'cancellation',
      question: 'What is your cancellation policy?',
      answer: 'Cancellation policies vary by booking. Free cancellation is available on many properties up to 24-48 hours before check-in. Specific policies are clearly displayed during the booking process.'
    },
    {
      category: 'cancellation',
      question: 'How do I request a refund?',
      answer: 'To request a refund, log into your account and cancel your eligible booking. Refunds are processed according to the bookings cancellation policy and typically take 5-10 business days to appear on your statement.'
    }
  ];

  const toggleItem = (questionId) => {
    setExpandedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(questionId)) {
        newSet.delete(questionId);
      } else {
        newSet.add(questionId);
      }
      return newSet;
    });
  };

  const filteredFAQs = faqData.filter(faq => {
    const matchesCategory = activeCategory === 'all' || faq.category === activeCategory;
    const matchesSearch = faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         faq.answer.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="min-h-screen bg-gray-50 py-24 px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="max-w-4xl mx-auto text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Frequently Asked Questions
        </h1>
        <p className="text-lg text-gray-600">
          Find answers to common questions about our services and travel arrangements.
        </p>
      </div>

      {/* Search Bar */}
      <div className="max-w-2xl mx-auto mb-12">
        <div className="relative">
          <input
            type="text"
            placeholder="Search your question..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-3 pl-12 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          <Search className="absolute left-4 top-3.5 text-gray-400 w-5 h-5" />
        </div>
      </div>

      {/* Categories */}
      <div className="max-w-4xl mx-auto mb-12">
        <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
          {categories.map(category => {
            const Icon = category.icon;
            return (
              <button
                key={category.id}
                onClick={() => setActiveCategory(category.id)}
                className={`p-4 rounded-lg flex flex-col items-center justify-center transition-all ${
                  activeCategory === category.id
                    ? 'bg-green-500 text-white'
                    : 'bg-white text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Icon className="w-6 h-6 mb-2" />
                <span className="text-sm font-medium">{category.name}</span>
              </button>
            );
          })}
        </div>
      </div>

      {/* FAQ Items */}
      <div className="max-w-3xl mx-auto">
        <div className="space-y-4">
          {filteredFAQs.map((faq, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm overflow-hidden"
            >
              <button
                onClick={() => toggleItem(index)}
                className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-50"
              >
                <span className="font-medium text-gray-900">{faq.question}</span>
                {expandedItems.has(index) ? (
                  <ChevronUp className="w-5 h-5 text-gray-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
              {expandedItems.has(index) && (
                <div className="px-6 py-4 bg-gray-50">
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Contact Support */}
      <div className="max-w-2xl mx-auto mt-12 text-center bg-white p-8 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">
          Still have questions?
        </h2>
        <p className="text-gray-600 mb-6">
          Can't find the answer you're looking for? Please contact our support team.
        </p>
        <button className="bg-green-500 text-white px-6 py-3 rounded-lg font-medium hover:bg-green-600 transition-colors">
         info@thethemostreviewed.com
        </button>
      </div>
    </div>
  );
};

export default FAQPage;