import hotel01 from "../../images/hotels/turkey/hotel01.jpg";
import hotel02 from "../../images/hotels/turkey/hotel02.jpg";
import hotel03 from "../../images/hotels/turkey/hotel03.jpg";
import hotel04 from "../../images/hotels/turkey/hotel04.jpg";
import hotel05 from "../../images/hotels/turkey/hotel05.jpg";
import hotel06 from "../../images/hotels/turkey/hotel06.jpg";
import hotel07 from "../../images/hotels/turkey/hotel07.jpg";
import hotel08 from "../../images/hotels/turkey/hotel08.jpg";
import hotel09 from '../../images/hotels/turkey/hotel09.jpg';

export const turkeyHotels = [
    //1
  {
    name: "Raffles Istanbul",
    country: "Turkey",
    imageUrl: hotel01,
    description:
      "Luxurious hotel in the Zorlu Center with stunning Bosphorus views, exceptional service, and world-class dining.",
    price: "$650/night",
    rating: 4.9,
    link: "https://www.tripadvisor.com/Hotel_Review-g293974-d6781688-Reviews-Raffles_Istanbul-Istanbul.html",
  },
  //2
  {
    name: "Four Seasons Hotel Istanbul at the Bosphorus",
    imageUrl: hotel02,
    country: "Turkey",
    description:
      "Historic palace hotel on the Bosphorus waterfront offering Ottoman luxury and spectacular strait views.",
    price: "$580/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g293974-d1087766-Reviews-Four_Seasons_Hotel_Istanbul_at_the_Bosphorus-Istanbul.html",
  },
  //3
  {
    name: "Mandarin Oriental Bosphorus",
    imageUrl: hotel03,
    country: "Turkey",
    description:
      "Contemporary luxury hotel with panoramic Bosphorus views, exceptional restaurants, and a world-class spa.",
    price: "$550/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g293974-d23140838-Reviews-Mandarin_Oriental_Bosphorus_Istanbul-Istanbul.html",
  },
  //4
  {
    name: "The Peninsula Istanbul",
    imageUrl: hotel04,
    country: "Turkey",
    description:
      "Newly opened waterfront property combining historical architecture with modern luxury and stunning views.",
    price: "$620/night",
    rating: 4.9,
    link: "https://www.tripadvisor.com/Hotel_Review-g293974-d24159936-Reviews-The_Peninsula_Istanbul-Istanbul.html",
  },
  //5
  {
    name: "Shangri-La Bosphorus",
    imageUrl: hotel05,
    country: "Turkey",
    description:
      "Elegant hotel offering Asian-inspired luxury, waterfront views, and exceptional service standards.",
    price: "$490/night",
    rating: 4.7,
    link: "hhttps://www.tripadvisor.com/Hotel_Review-g293974-d4176982-Reviews-Shangri_La_Bosphorus_Istanbul-Istanbul.html",
  },
  //6
  {
    name: "Six Senses Kocatas Mansions",
    imageUrl: hotel06,
    country: "Turkey",
    description:
      "Historic mansion converted into a luxury resort, offering unique experiences and Bosphorus views.",
    price: "$680/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g293974-d19313807-Reviews-Six_Senses_Kocatas_Mansions-Istanbul.html",
  },
  //7
  {
    name: "Ciragan Palace Kempinski",
    imageUrl: hotel07,
    country: "Turkey",
    description:
      "Ottoman imperial palace turned luxury hotel, offering ultimate luxury and historical grandeur.",
    price: "$750/night",
    rating: 4.7,
    link: "https://www.tripadvisor.com/Hotel_Review-g293974-d294616-Reviews-Ciragan_Palace_Kempinski_Istanbul-Istanbul.html",
  },
  //8
  {
    name: "Park Hyatt Istanbul - Macka Palas",
    imageUrl: hotel08,
    country: "Turkey",
    description:
      "Art Deco-style luxury hotel in the fashionable Nisantasi district with modern amenities.",
    price: "$420/night",
    rating: 4.6,
    link: "https://www.tripadvisor.com/Hotel_Review-g293974-d1147077-Reviews-Park_Hyatt_Istanbul_Macka_Palas-Istanbul.html",
  },
  //9
  {
    name: "The Ritz-Carlton Istanbul",
    imageUrl: hotel09,
    country: "Turkey",
    description:
      "Elegant luxury hotel offering spectacular Bosphorus views, Turkish spa, and impeccable service.",
    price: "$460/night",
    rating: 4.6,
    link: "https://www.tripadvisor.com/Hotel_Review-g293974-d294809-Reviews-The_Ritz_Carlton_Istanbul-Istanbul.html",
  },
];