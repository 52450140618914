
const HotelCard = ({ hotel }) => (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img
        src={hotel.imageUrl}
        alt={hotel.name}
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-2">{hotel.name}</h3>
        <p className="text-gray-600 mb-2">{hotel.description}</p>
        <div className="flex justify-between items-center mb-2">
          <span className="text-green-600 font-bold">
            {hotel.price} per night
          </span>
          <span className="text-yellow-500">★ {hotel.rating}</span>
        </div>
        <a
          href={hotel.link}
          target="_blank"
          rel="noopener noreferrer"
          className="block w-full text-center bg-green-500 text-white py-2 rounded hover:bg-green-600 transition duration-300"
        >
          View on Trivago
        </a>
      </div>
    </div>
  );

  export default HotelCard