import { japanHotels } from './japan';
import { turkeyHotels } from './turkey';
import  {franceHotels} from './france'
import  {mexicoHotels} from './mexico'

export const allHotels = [
  ...japanHotels,
  ...turkeyHotels,
  ...franceHotels,
  ...mexicoHotels
];