export const turkeyActivities = [
  {
    id: 1,
    title: "Hagia Sophia",
    description: "A UNESCO World Heritage site that was once a church, then a mosque, a museum, and now a mosque again. Features stunning Byzantine architecture and ancient mosaics.",
    rating: 4.9,
    duration: "2-3 hours",
    category: "Cultural",
    country: "Turkey",
    price: "Free",
    link: "https://www.tripadvisor.com/Attraction_Review-g293974-d294497-Reviews-Hagia_Sophia_Mosque-Istanbul.html",
    location: "Sultan Ahmet, Ayasofya Meydanı No:1, Istanbul"
  },
  {
    id: 2,
    title: "Blue Mosque (Sultan Ahmed Mosque)",
    description: "Iconic Ottoman-era mosque known for its blue Iznik tiles and six minarets. A masterpiece of Islamic architecture.",
    rating: 4.7,
    duration: "1-2 hours",
    category: "Cultural",
    country: "Turkey",
    link: "https://www.tripadvisor.com/Attraction_Review-g293974-d294495-Reviews-Blue_Mosque-Istanbul.html",
    price: "Free",
    location: "Sultanahmet Mah, Istanbul"
  },
  {
    id: 3,
    title: "Topkapi Palace",
    description: "Former residence of Ottoman sultans, now a museum showcasing imperial collections, sacred relics, and stunning courtyards with Bosphorus views.",
    rating: 4.8,
    duration: "3-4 hours",
    category: "Cultural",
    country: "Turkey",
    price: "$15",
    link: "https://www.tripadvisor.com/Attraction_Review-g293974-d294547-Reviews-TopkapI_Palace-Istanbul.html",
    location: "Cankurtaran, 34122 Fatih/Istanbul"
  },
  {
    id: 4,
    title: "Grand Bazaar",
    description: "One of the world's oldest and largest covered markets with over 4,000 shops selling carpets, jewelry, ceramics, and more.",
    rating: 4.6,
    duration: "2-4 hours",
    category: "Food &Shopping",
    country: "Turkey",
    price: "Free",
    link: "https://www.tripadvisor.com/Attraction_Review-g293974-d294496-Reviews-Grand_Bazaar-Istanbul.html",
    location: "Beyazıt Mh., 34126 Fatih/Istanbul"
  },
  {
    id: 5,
    title: "Basilica Cistern",
    description: "Ancient underground water reservoir featuring remarkable Roman architecture and Medusa head columns.",
    rating: 4.7,
    duration: "1 hour",
    category: "Historical",
    country: "Turkey",
    price: "$12",
    link: "https://www.tripadvisor.com/Attraction_Review-g293974-d294555-Reviews-Basilica_Cistern-Istanbul.html",
    location: "Alemdar, Yerebatan Cd. 1/3, Istanbul"
  },
  {
    id: 6,
    title: "Bosphorus Cruise",
    description: "Scenic boat tour through the Bosphorus strait, offering views of palaces, fortresses, and bridges spanning two continents.",
    rating: 4.8,
    duration: "2-6 hours",
    category: "Tours",
    country: "Turkey",
    price: "$20-50",
    link: "https://www.tripadvisor.com/Attraction_Review-g293974-d295190-Reviews-Bosphorus_Cruise-Istanbul.html",
    location: "Eminonu or Besiktas Pier, Istanbul"
  },


];