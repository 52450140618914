import React, { useState } from "react";
import {
  ChevronRight,
  Phone,
  Mail,
  MessageCircle,
} from "lucide-react";

const TableOfContents = ({ sections, activeSection, setActiveSection }) => (
  <div className="sticky top-24 bg-white rounded-xl shadow-soft p-6">
    <h3 className="font-serif text-xl text-gray-900 mb-4">Contents</h3>
    <ul className="space-y-2">
      {sections.map((section) => (
        <li key={section.id}>
          <button
            onClick={() => setActiveSection(section.id)}
            className={`text-left w-full px-3 py-2 rounded-lg transition-colors ${
              activeSection === section.id
                ? "bg-gold-50 text-gold-900"
                : "text-gray-600 hover:bg-neutral-50"
            }`}
          >
            {section.title}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

const Section = ({ title, children, className = "" }) => (
  <section className={`bg-white rounded-xl shadow-soft p-8 ${className}`}>
    <h2 className="text-2xl font-serif text-gray-900 mb-6">{title}</h2>
    {children}
  </section>
);

const CookiesSection = () => (
  <Section title="Cookies & Privacy">
    <div className="space-y-8">
      {/* Cookie Policy Introduction */}
      <div className="prose prose-lg max-w-none">
        <p className="lead text-gray-600">
          themostreviewed uses cookies and similar technologies to enhance your
          browsing experience and provide personalized service.
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        {[
          {
            title: "Essential Cookies",
            description: "Required for basic site functionality",
            examples: [
              "Authentication status",
              "Shopping cart data",
              "Security tokens",
              "Session management",
            ],
            canDisable: false,
          },
          {
            title: "Preference Cookies",
            description: "Remember your settings and preferences",
            examples: [
              "Language selection",
              "Currency preference",
              "Search history",
              "Recently viewed",
            ],
            canDisable: true,
          },
          {
            title: "Analytics Cookies",
            description: "Help us improve our services",
            examples: [
              "Page visit statistics",
              "Traffic sources",
              "User behavior",
              "Performance data",
            ],
            canDisable: true,
          },
          {
            title: "Marketing Cookies",
            description: "Used for personalized advertisements",
            examples: [
              "Ad personalization",
              "Remarketing",
              "Social media sharing",
              "Partner integrations",
            ],
            canDisable: true,
          },
        ].map((category, index) => (
          <div
            key={index}
            className="bg-white rounded-lg border border-gray-200 overflow-hidden"
          >
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <h3 className="font-serif text-xl text-gray-900">
                  {category.title}
                </h3>
                {category.canDisable && (
                  <div className="relative inline-block w-10 h-6 transition duration-200 ease-linear rounded-full cursor-pointer">
                    <input
                      type="checkbox"
                      className="absolute w-10 h-6 transition duration-200 ease-linear rounded-full cursor-pointer appearance-none bg-gray-300 peer/switch checked:bg-green-500"
                      id={`switch-${index}`}
                    />
                    <label
                      htmlFor={`switch-${index}`}
                      className="absolute left-0 w-6 h-6 mb-2 transition duration-200 ease-linear transform bg-white rounded-full cursor-pointer peer-checked/switch:translate-x-full"
                    ></label>
                  </div>
                )}
              </div>
              <p className="text-gray-600 mb-4">{category.description}</p>
              <ul className="space-y-2">
                {category.examples.map((example, idx) => (
                  <li
                    key={idx}
                    className="flex items-center text-gray-600 text-sm"
                  >
                    <span className="w-1.5 h-1.5 bg-gray-400 rounded-full mr-2"></span>
                    {example}
                  </li>
                ))}
              </ul>
            </div>
            {!category.canDisable && (
              <div className="px-6 py-3 bg-gray-50 border-t border-gray-200">
                <p className="text-sm text-gray-500">
                  Required for site functionality
                </p>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h3 className="font-serif text-xl text-gray-900 mb-4">
          Third Party Cookies
        </h3>
        <div className="space-y-4">
          <p className="text-gray-600">
            We work with trusted partners who may also place cookies on your
            device:
          </p>
          <ul className="grid md:grid-cols-2 gap-4">
            {[
              {
                partner: "Google Analytics",
                purpose: "Website analytics and performance monitoring",
              },
              {
                partner: "Payment Processors",
                purpose: "Secure payment processing",
              },
              {
                partner: "Social Media",
                purpose: "Content sharing and social features",
              },
              {
                partner: "Advertising Partners",
                purpose: "Personalized advertisements",
              },
            ].map((partner, index) => (
              <div key={index} className="p-4 bg-gray-50 rounded-lg">
                <p className="font-medium text-gray-900 mb-1">
                  {partner.partner}
                </p>
                <p className="text-sm text-gray-600">{partner.purpose}</p>
              </div>
            ))}
          </ul>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h3 className="font-serif text-xl text-gray-900 mb-4">
          Managing Your Preferences
        </h3>
        <div className="space-y-4">
          <p className="text-gray-600">
            You can manage your cookie preferences in several ways:
          </p>
          <div className="space-y-6">
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <p className="font-medium text-gray-900">All Cookies</p>
                <p className="text-sm text-gray-600">
                  Enable or disable all non-essential cookies
                </p>
              </div>
              <div className="relative inline-block w-10 h-6">
                <input
                  type="checkbox"
                  className="toggle-checkbox"
                  id="toggle-all"
                />
                <label className="toggle-label" htmlFor="toggle-all"></label>
              </div>
            </div>
            <button className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition-colors">
              Save Preferences
            </button>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

const TermsOfService = () => {
  const [activeSection, setActiveSection] = useState("introduction");

  const sections = [
    { id: "introduction", title: "Introduction" },
    { id: "definitions", title: "Definitions" },
    { id: "services", title: "Our Services" },
    { id: "cookies", title: "Cookies & Privacy" },
    { id: "bookings", title: "Bookings & Reservations" },
    { id: "cancellations", title: "Cancellation Policy" },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-neutral-50 to-neutral-100">
      {/* Hero Section */}
      <div className="relative bg-green-800 text-white py-20">
        <div className="relative max-w-6xl mx-auto px-6">
          <h1 className="text-5xl md:text-6xl font-serif mb-6">
            Terms of Service
          </h1>
          <p className="text-xl max-w-2xl leading-relaxed text-gray-300">
            Welcome to themostreviewed, where luxury meets exceptional service. Our
            terms are designed to ensure a seamless and premium experience.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-6 py-16">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          <div className="lg:col-span-3">
            <TableOfContents
              sections={sections}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
          </div>

          <div className="lg:col-span-9 space-y-8">
            {activeSection === "introduction" && <IntroductionSection />}
            {activeSection === "definitions" && <DefinitionsSection />}
            {activeSection === "services" && <ServicesSection />}
            {activeSection === "cookies" && <CookiesSection />}
            {activeSection === "bookings" && <BookingsSection />}
            {activeSection === "cancellations" && <CancellationSection />}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="bg-white border-t border-gray-200">
        <div className="max-w-6xl mx-auto px-6 py-8">
          <div className="grid md:grid-cols-3 gap-6">
            <div className="flex items-center justify-center md:justify-start">
              <Phone className="w-5 h-5 text-gold-500 mr-2" />
              <span>+31 (888) themostreviewed</span>
            </div>
            <div className="flex items-center justify-center">
              <Mail className="w-5 h-5 text-gold-500 mr-2" />
              <span>support@themostreviewed.com</span>
            </div>
            <div className="flex items-center justify-center md:justify-end">
              <MessageCircle className="w-5 h-5 text-gold-500 mr-2" />
              <span>24/7 Live Chat Available</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const IntroductionSection = () => (
  <Section title="Introduction">
    <div className="space-y-8">
      <div className="prose prose-lg max-w-none">
        <p className="lead text-gray-700">
          Welcome to themostreviewed, the premier destination for luxury travel and
          exceptional accommodations. These Terms of Service ("Terms") outline
          our commitment to providing you with an unparalleled hospitality
          experience.
        </p>
      </div>

      {/* Key Information */}
      <div className="bg-neutral-50 rounded-lg p-6">
        <h3 className="font-serif text-xl text-gray-900 mb-4">
          Important Information
        </h3>
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <h4 className="font-medium text-gray-900 mb-2">Agreement Basics</h4>
            <ul className="space-y-2">
              <li className="flex items-start">
                <ChevronRight className="w-5 h-5 text-gold-500 mt-1 mr-2" />
                <span className="text-gray-600">
                  These Terms constitute a legally binding agreement between you
                  and themostreviewed
                </span>
              </li>
              <li className="flex items-start">
                <ChevronRight className="w-5 h-5 text-gold-500 mt-1 mr-2" />
                <span className="text-gray-600">
                  By accessing our services, you accept and agree to be bound by
                  these Terms
                </span>
              </li>
              <li className="flex items-start">
                <ChevronRight className="w-5 h-5 text-gold-500 mt-1 mr-2" />
                <span className="text-gray-600">
                  Last updated: January 2024
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="font-medium text-gray-900 mb-2">Service Coverage</h4>
            <ul className="space-y-2">
              <li className="flex items-start">
                <ChevronRight className="w-5 h-5 text-gold-500 mt-1 mr-2" />
                <span className="text-gray-600">
                  Applies to all services, including website, mobile apps, and
                  bookings
                </span>
              </li>
              <li className="flex items-start">
                <ChevronRight className="w-5 h-5 text-gold-500 mt-1 mr-2" />
                <span className="text-gray-600">
                  Covers all interactions with our platform and services
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Updates & Changes */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h3 className="font-serif text-xl text-gray-900 mb-4">
          Updates to Terms
        </h3>
        <div className="space-y-4">
          <p className="text-gray-600">
            We may update these Terms periodically to reflect:
          </p>
          <div className="grid md:grid-cols-3 gap-4">
            <div className="p-4 bg-neutral-50 rounded-lg">
              <h4 className="font-medium text-gray-900 mb-2">
                Service Changes
              </h4>
              <p className="text-sm text-gray-600">
                Updates to our offerings, features, and functionality
              </p>
            </div>
            <div className="p-4 bg-neutral-50 rounded-lg">
              <h4 className="font-medium text-gray-900 mb-2">
                Legal Requirements
              </h4>
              <p className="text-sm text-gray-600">
                Changes in applicable laws and regulations
              </p>
            </div>
            <div className="p-4 bg-neutral-50 rounded-lg">
              <h4 className="font-medium text-gray-900 mb-2">
                Security Enhancements
              </h4>
              <p className="text-sm text-gray-600">
                Improvements to our security and privacy measures
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Our Commitment */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h3 className="font-serif text-xl text-gray-900 mb-4">
          Our Commitment to Excellence
        </h3>
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <h4 className="font-medium text-gray-900 mb-2">
              Service Standards
            </h4>
            <ul className="space-y-2">
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-3"></span>
                <span className="text-gray-600">24/7 concierge support</span>
              </li>
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-3"></span>
                <span className="text-gray-600">
                  Verified luxury properties
                </span>
              </li>
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-3"></span>
                <span className="text-gray-600">
                  Personalized travel experiences
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="font-medium text-gray-900 mb-2">
              Quality Assurance
            </h4>
            <ul className="space-y-2">
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-3"></span>
                <span className="text-gray-600">
                  Regular property inspections
                </span>
              </li>
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-3"></span>
                <span className="text-gray-600">Verified guest reviews</span>
              </li>
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-3"></span>
                <span className="text-gray-600">Premium partner selection</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

const DefinitionsSection = () => (
  <Section title="Definitions">
    <div className="space-y-8">
      <p className="text-gray-600 leading-relaxed">
        To ensure clarity and understanding, the following terms have specific
        meanings within these Terms of Service:
      </p>

      {/* Core Definitions */}
      <div className="grid gap-6">
        {[
          {
            term: "themostreviewed Platform",
            definition:
              "Our comprehensive digital platform including our website (themostreviewed.com), mobile applications, and all related services and tools provided to facilitate luxury travel bookings and experiences.",
            examples: [
              "Website interface",
              "Mobile applications",
              "Booking systems",
              "Customer service portals",
            ],
          },
          {
            term: "Luxury Services",
            definition:
              "The premium travel and accommodation services offered through our platform, characterized by high-quality standards and exceptional customer service.",
            examples: [
              "5-star hotel bookings",
              "Private villa rentals",
              "Exclusive experiences",
              "Concierge services",
            ],
          },
          {
            term: "Member",
            definition:
              "An individual or entity who has registered and maintains an active account on themostreviewed platform, gaining access to our premium services and benefits.",
            examples: [
              "Individual travelers",
              "Corporate accounts",
              "Travel agents",
              "Event planners",
            ],
          },
          {
            term: "Premium Partner",
            definition:
              "Carefully vetted and selected luxury accommodation providers and service vendors who meet our stringent quality standards.",
            examples: [
              "Luxury hotels",
              "Boutique resorts",
              "High-end villa owners",
              "Premium experience providers",
            ],
          },
        ].map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-lg border border-gray-200 p-6"
          >
            <h3 className="font-serif text-xl text-gray-900 mb-3">
              {item.term}
            </h3>
            <p className="text-gray-600 mb-4">{item.definition}</p>
            <div className="bg-neutral-50 rounded-lg p-4">
              <h4 className="font-medium text-gray-900 mb-2">Includes:</h4>
              <ul className="grid grid-cols-2 gap-2">
                {item.examples.map((example, idx) => (
                  <li key={idx} className="flex items-center text-gray-600">
                    <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-2"></span>
                    {example}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Additional Terms */}
      <div className="bg-neutral-50 rounded-lg p-6">
        <h3 className="font-serif text-xl text-gray-900 mb-4">
          Additional Important Terms
        </h3>
        <div className="grid md:grid-cols-2 gap-6">
          {[
            {
              term: "Booking",
              definition:
                "A confirmed reservation of any service through our platform",
            },
            {
              term: "Luxury Experience",
              definition:
                "Curated high-end activities and services available through our platform",
            },
            {
              term: "Service Period",
              definition:
                "The duration for which any booked service is provided",
            },
            {
              term: "Platform Fee",
              definition:
                "Charges applied for using our service and processing bookings",
            },
          ].map((item, index) => (
            <div key={index} className="bg-white rounded-lg p-4">
              <p className="font-medium text-gray-900 mb-1">{item.term}</p>
              <p className="text-sm text-gray-600">{item.definition}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Section>
);
const ServicesSection = () => (
  <Section title="Our Services">
    <div className="space-y-8">
      {/* Introduction */}
      <div className="prose prose-lg max-w-none">
        <p className="lead text-gray-700">
          At themostreviewed, we offer a comprehensive suite of luxury travel services designed to create unforgettable experiences for our distinguished guests.
        </p>
      </div>

      {/* Premium Services Grid */}
      <div className="grid md:grid-cols-2 gap-6">
        {[
          {
            title: "Luxury Accommodations",
            description: "Hand-picked premium properties worldwide",
            features: [
              "5-star hotels and resorts",
              "Private luxury villas",
              "Boutique properties",
              "Exclusive penthouses",
              "Serviced apartments",
              "Historic mansions"
            ],
            icon: "Hotel"
          },
          {
            title: "Concierge Services",
            description: "24/7 personalized assistance",
            features: [
              "Restaurant reservations",
              "Event planning",
              "Private tours",
              "Transportation",
              "Personal shopping",
              "Wellness services"
            ],
            icon: "Concierge"
          },
          {
            title: "Exclusive Experiences",
            description: "Curated activities and unique adventures",
            features: [
              "Private yacht charters",
              "Helicopter tours",
              "Wine tasting",
              "Cooking classes",
              "Cultural experiences",
              "Adventure activities"
            ],
            icon: "Experience"
          },
          {
            title: "Premium Transportation",
            description: "Luxury travel arrangements",
            features: [
              "Private jet services",
              "Chauffeur services",
              "Luxury car rentals",
              "Airport transfers",
              "Yacht charters",
              "Helicopter transfers"
            ],
            icon: "Transport"
          }
        ].map((service, index) => (
          <div key={index} className="bg-white rounded-lg border border-gray-200 p-6">
            <h3 className="font-serif text-xl text-gray-900 mb-3">{service.title}</h3>
            <p className="text-gray-600 mb-4">{service.description}</p>
            <ul className="grid grid-cols-2 gap-2">
              {service.features.map((feature, idx) => (
                <li key={idx} className="flex items-center text-gray-600 text-sm">
                  <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-2"></span>
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Service Standards */}
      <div className="bg-neutral-50 rounded-lg p-6">
        <h3 className="font-serif text-xl text-gray-900 mb-4">Our Service Standards</h3>
        <div className="grid md:grid-cols-3 gap-6">
          {[
            {
              title: "Quality Assurance",
              points: [
                "Regular property inspections",
                "Strict partner vetting",
                "Guest feedback integration"
              ]
            },
            {
              title: "Customer Support",
              points: [
                "24/7 dedicated assistance",
                "Multi-language support",
                "Emergency response team"
              ]
            },
            {
              title: "Premium Benefits",
              points: [
                "VIP welcome services",
                "Exclusive member perks",
                "Loyalty rewards program"
              ]
            }
          ].map((standard, index) => (
            <div key={index} className="space-y-2">
              <h4 className="font-medium text-gray-900">{standard.title}</h4>
              <ul className="space-y-2">
                {standard.points.map((point, idx) => (
                  <li key={idx} className="flex items-center text-gray-600">
                    <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-2"></span>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Section>
);

const BookingsSection = () => (
  <Section title="Bookings & Reservations">
    <div className="space-y-8">
      {/* Introduction */}
      <div className="prose prose-lg max-w-none">
        <p className="lead text-gray-700">
          Our booking process is designed to be seamless and transparent, ensuring you can secure your luxury accommodations with confidence.
        </p>
      </div>

      {/* Booking Process */}
      <div className="grid md:grid-cols-3 gap-6">
        {[
          {
            step: "1",
            title: "Search & Select",
            description: "Browse our curated collection of luxury properties and select your preferred accommodation.",
            points: [
              "Filter by preferences",
              "View detailed information",
              "Compare options",
              "Check availability"
            ]
          },
          {
            step: "2",
            title: "Book & Confirm",
            description: "Complete your booking with our secure reservation system.",
            points: [
              "Choose room types",
              "Select additional services",
              "Provide guest details",
              "Secure payment"
            ]
          },
          {
            step: "3",
            title: "Prepare & Enjoy",
            description: "Receive confirmation and prepare for your luxury stay.",
            points: [
              "Booking confirmation",
              "Pre-arrival support",
              "Special requests",
              "Concierge assistance"
            ]
          }
        ].map((step, index) => (
          <div key={index} className="bg-white rounded-lg border border-gray-200 p-6">
            <div className="flex items-center mb-4">
              <span className="w-8 h-8 rounded-full bg-gold-500 text-white flex items-center justify-center font-bold mr-3">
                {step.step}
              </span>
              <h3 className="font-serif text-xl text-gray-900">{step.title}</h3>
            </div>
            <p className="text-gray-600 mb-4">{step.description}</p>
            <ul className="space-y-2">
              {step.points.map((point, idx) => (
                <li key={idx} className="flex items-center text-gray-600">
                  <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-2"></span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Payment Information */}
      <div className="bg-neutral-50 rounded-lg p-6">
        <h3 className="font-serif text-xl text-gray-900 mb-4">Payment & Security</h3>
        <div className="grid md:grid-cols-2 gap-6">
          {[
            {
              title: "Payment Options",
              points: [
                "Major credit cards accepted",
                "Secure payment processing",
                "Multiple currency support",
                "Flexible payment plans"
              ]
            },
            {
              title: "Booking Security",
              points: [
                "Encrypted transactions",
                "Fraud protection",
                "Secure booking guarantee",
                "Privacy protection"
              ]
            }
          ].map((info, index) => (
            <div key={index} className="space-y-2">
              <h4 className="font-medium text-gray-900">{info.title}</h4>
              <ul className="space-y-2">
                {info.points.map((point, idx) => (
                  <li key={idx} className="flex items-center text-gray-600">
                    <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-2"></span>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Section>
);

const CancellationSection = () => (
  <Section title="Cancellation Policy">
    <div className="space-y-8">
      {/* Introduction */}
      <div className="prose prose-lg max-w-none">
        <p className="lead text-gray-700">
          We understand that plans can change. Our cancellation policies are designed to be fair and transparent while protecting both our guests and partners.
        </p>
      </div>

      {/* Cancellation Types */}
      <div className="grid md:grid-cols-3 gap-6">
        {[
          {
            type: "Flexible",
            description: "Maximum flexibility for your peace of mind",
            terms: [
              "Free cancellation up to 48 hours before check-in",
              "Full refund of accommodation costs",
              "No questions asked policy",
              "Instant processing"
            ],
            badge: "Most Popular"
          },
          {
            type: "Moderate",
            description: "Balance between flexibility and security",
            terms: [
              "Free cancellation up to 5 days before check-in",
              "75% refund if cancelled within 5 days",
              "Standard processing time",
              "Booking transferable"
            ]
          },
          {
            type: "Strict",
            description: "Applies to special rates and peak seasons",
            terms: [
              "Free cancellation up to 7 days before check-in",
              "50% refund if cancelled within 7 days",
              "No refund within 48 hours",
              "Special conditions may apply"
            ]
          }
        ].map((policy, index) => (
          <div key={index} className="bg-white rounded-lg border border-gray-200 p-6 relative">
            {policy.badge && (
              <span className="absolute top-4 right-4 bg-gold-500 text-white px-2 py-1 rounded text-sm">
                {policy.badge}
              </span>
            )}
            <h3 className="font-serif text-xl text-gray-900 mb-3">{policy.type}</h3>
            <p className="text-gray-600 mb-4">{policy.description}</p>
            <ul className="space-y-2">
              {policy.terms.map((term, idx) => (
                <li key={idx} className="flex items-center text-gray-600 text-sm">
                  <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-2"></span>
                  {term}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Refund Process */}
      <div className="bg-neutral-50 rounded-lg p-6">
        <h3 className="font-serif text-xl text-gray-900 mb-4">Refund Process</h3>
        <div className="grid md:grid-cols-2 gap-6">
          {[
            {
              title: "Standard Refunds",
              points: [
                "Processed within 5-7 business days",
                "Returned to original payment method",
                "Email confirmation provided",
                "Support team assistance available"
              ]
            },
            {
              title: "Special Cases",
              points: [
                "Force majeure consideration",
                "Medical emergency exceptions",
                "Case-by-case evaluation",
                "Documentation may be required"
              ]
            }
          ].map((process, index) => (
            <div key={index} className="space-y-2">
              <h4 className="font-medium text-gray-900">{process.title}</h4>
              <ul className="space-y-2">
                {process.points.map((point, idx) => (
                  <li key={idx} className="flex items-center text-gray-600">
                    <span className="w-1.5 h-1.5 bg-gold-500 rounded-full mr-2"></span>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Section>
);

export default TermsOfService;
