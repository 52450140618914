import React from 'react';

const stats = [
  { value: '12', label: 'Years Experience' },
  { value: '97%', label: 'Retention Rate' },
  { value: '8k', label: 'Tour Completed' },
  { value: '19k', label: 'Happy Travellers' },
];

const StatCard = ({ value, label }) => (
  <div className="relative w-48 h-48 mx-4 my-8">
    <div className="absolute inset-0 bg-green-50 rounded-full"></div>
    <div className="absolute inset-1 bg-white rounded-full flex flex-col items-center justify-center">
      <span className="text-4xl font-bold text-gray-800">{value}</span>
      <span className="text-sm text-gray-600 text-center mt-2">{label}</span>
    </div>
    <div className="absolute inset-0">
      <div className="w-full h-full animate-spin-slow">
        <div className="absolute top-0 right-1/2 w-3 h-3 bg-green-400 rounded-full transform translate-x-1/2 -translate-y-1/2"></div>
      </div>
    </div>
  </div>
);

const CircularStatsCards = () => {
  return (
    <section className="py-16 px-4 bg-white">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-wrap justify-center items-center">
          {stats.map((stat, index) => (
            <StatCard key={index} {...stat} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CircularStatsCards;