import hotel01 from "../../images/hotels/france/hotel01.jpg";
import hotel02 from "../../images/hotels/france/hotel02.jpg";
import hotel03 from "../../images/hotels/france/hotel03.jpg";
import hotel04 from "../../images/hotels/france/hotel04.jpg";
import hotel05 from "../../images/hotels/france/hotel05.jpg";
import hotel06 from "../../images/hotels/france/hotel06.jpg";
import hotel07 from "../../images/hotels/france/hotel07.jpg";
import hotel08 from "../../images/hotels/france/hotel08.jpg";
import hotel09 from '../../images/hotels/france/hotel09.jpg';

export const franceHotels = [
  {
    name: "Ritz Paris",
    country: "France",
    imageUrl: hotel01,
    description:
      "Iconic luxury hotel in Place Vendôme offering timeless elegance, world-famous bars, and Michelin-starred dining.",
    price: "$1,450/night",
    rating: 4.9,
    link: "https://www.tripadvisor.com/Hotel_Review-g187147-d188728-Reviews-Ritz_Paris-Paris_Ile_de_France.html",
  },
  {
    name: "Four Seasons Hotel George V",
    imageUrl: hotel02,
    country: "France",
    description:
      "Art Deco landmark near Champs-Élysées featuring three Michelin-starred restaurants and luxury spa.",
    price: "$1,350/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g187147-d188975-Reviews-Four_Seasons_Hotel_George_V_Paris-Paris_Ile_de_France.html",
  },
  {
    name: "Le Bristol Paris",
    imageUrl: hotel03,
    country: "France",
    description:
      "Palace hotel offering French elegance, rooftop pool with Eiffel Tower views, and 4 Michelin stars.",
    price: "$1,250/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g187147-d188729-Reviews-Le_Bristol_Paris-Paris_Ile_de_France.html",
  },
  {
    name: "Cheval Blanc Paris",
    imageUrl: hotel04,
    country: "France",
    description:
      "Contemporary luxury hotel in historic La Samaritaine with Seine views and exceptional spa.",
    price: "$1,550/night",
    rating: 4.9,
    link: "https://www.tripadvisor.com/Hotel_Review-g187147-d21512039-Reviews-Cheval_Blanc_Paris-Paris_Ile_de_France.html",
  },
  {
    name: "La Réserve Paris Hotel and Spa",
    imageUrl: hotel05,
    country: "France",
    description:
      "Intimate palace hotel near Champs-Élysées with personalized service and elegant suites.",
    price: "$1,200/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g187147-d497267-Reviews-La_Reserve_Paris_Hotel_and_Spa-Paris_Ile_de_France.html",
  },
  {
    name: "Mandarin Oriental, Paris",
    imageUrl: hotel06,
    country: "France",
    description:
      "Modern luxury hotel on rue Saint-Honoré with peaceful garden and superb dining options.",
    price: "$1,150/night",
    rating: 4.7,
    link: "https://www.tripadvisor.com/Hotel_Review-g187147-d2041918-Reviews-Mandarin_Oriental_Paris-Paris_Ile_de_France.html",
  },
  {
    name: "Peninsula Paris",
    imageUrl: hotel07,
    country: "France",
    description:
      "Restored historic palace with rooftop restaurant offering spectacular city views.",
    price: "$1,300/night",
    rating: 4.8,
    link: "https://www.tripadvisor.com/Hotel_Review-g187147-d6678144-Reviews-The_Peninsula_Paris-Paris_Ile_de_France.html",
  },
  {
    name: "Hôtel Plaza Athénée",
    imageUrl: hotel08,
    country: "France",
    description:
      "Legendary hotel on avenue Montaigne with spectacular Eiffel Tower views and haute couture style.",
    price: "$1,400/night",
    rating: 4.7,
    link: "https://www.tripadvisor.com/Hotel_Review-g187147-d188730-Reviews-Hotel_Plaza_Athenee-Paris_Ile_de_France.html",
  },
  {
    name: "Le Royal Monceau-Raffles Paris",
    imageUrl: hotel09,
    country: "France",
    description:
      "Contemporary palace hotel near Arc de Triomphe with art gallery and exclusive spa.",
    price: "$1,100/night",
    rating: 4.6,
    link: "https://www.tripadvisor.com/Hotel_Review-g187147-d197528-Reviews-Le_Royal_Monceau_Raffles_Paris-Paris_Ile_de_France.html",
  },
];