import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Send, Phone, Mail, MapPin, ArrowUp, Facebook, Twitter, Linkedin, Instagram, ChevronDown, ChevronUp } from 'lucide-react';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState('');
  const [openFAQ, setOpenFAQ] = useState(null);

// Social Links Component
const renderSocialLinks = () => {
  const socials = [
    { icon: <Facebook className="w-5 h-5" />, href: 'https://facebook.com' },
    { icon: <Twitter className="w-5 h-5" />, href: 'https://twitter.com' },
    { icon: <Linkedin className="w-5 h-5" />, href: 'https://linkedin.com' },
    { icon: <Instagram className="w-5 h-5" />, href: 'https://instagram.com' }
  ];

  return (
    <div className="flex space-x-4">
      {socials.map((social) => (
        <a
          key={social.href} // Corrected: key should be inside the opening tag.
          href={social.href}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-teal-800 p-2 rounded-full hover:bg-teal-700 transition-colors duration-300"
        >
          {social.icon}
        </a>
      ))}
    </div>
  );
};


  // Quick Links Component
  const renderQuickLinks = () => {
    const links = [
      { name: 'Home ', path: '/' },
      { name: 'Japan hotels & activities', path: '/japan' },
      { name: 'Turkey hotels & activities', path: '/turkey' },
      { name: 'Mexico hotels & activities', path: '/mexico' },
      { name: 'France hotels & activities', path: '/france' },
      { name: 'Services', path: '/services' },
      { name: 'Contact us', path: '/contact-us' },
      { name: 'FAQ', path: '/Faq' },

      { name: 'Terms and conditions', path: '/terms-and-conditions' }
    ];

    return (
      <div>
        <h3 className="text-xl font-bold mb-4">Quick Links</h3>
        <ul className="space-y-2">
          {links.map((link) => (
            <li key={link.name}>
              <Link 
                to={link.path}
                className="hover:text-green-400 transition-colors duration-300"
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const faqs = [
    { 
      question: "How do I make a reservation?", 
      answer: "You can make a reservation through our website or contact our customer service." 
    },
    { 
      question: "What is the cancellation policy?", 
      answer: "Cancellation policies vary by property. Please check specific terms during booking." 
    },
    { 
      question: "Do you offer group bookings?", 
      answer: "Yes, we offer group bookings. Contact our sales team for details." 
    },
    { 
      question: "What amenities are included?", 
      answer: "Amenities vary by property. Check each listing for specific details." 
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setNotification('Please enter an email address');
      return;
    }
    setNotification('Thank you for subscribing!');
    setEmail('');
    setTimeout(() => setNotification(''), 3000);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-green-800 text-white py-12">
      <div className="container mx-auto px-4">
        {/* Newsletter */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-12">
          <h2 className="text-3xl font-bold mb-4 md:mb-0">
            Stay Updated with Latest Offers
          </h2>
          <form onSubmit={handleSubmit} className="flex flex-col w-full md:w-auto">
            <div className="flex">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="px-4 py-2 rounded-l-md bg-green-700 text-white placeholder-green-300"
              />
              <button
                type="submit"
                className="px-1 py-2 bg-green-600 rounded-r-md hover:bg-green-500"
              >
                Subscribe
                <Send className="ml-2 h-4 w-4 inline" />
              </button>
            </div>
            {notification && (
              <p className="mt-2 text-sm text-green-300">{notification}</p>
            )}
          </form>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-2xl font-bold mb-4">the most reviewed</h3>
            <p className="mb-4">Discover luxury in every stay</p>
            {renderSocialLinks()}
          </div>

          {/* Quick Links */}
          {renderQuickLinks()}

          {/* Contact Info */}
          <div>
            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
            <div className="space-y-4">
              <p className="flex items-center">
                <Phone className="mr-2 h-5 w-5" />
                +31 20 123 4567
              </p>
              <p className="flex items-center">
                <Mail className="mr-2 h-5 w-5" />
                info@themostreviewed.com
              </p>
              <p className="flex items-center">
                <MapPin className="mr-2 h-5 w-5" />
                Amsterdam, Netherlands
              </p>
            </div>
          </div>

          {/* FAQ */}
          <div>
            <h3 className="text-xl font-bold mb-4">FAQ</h3>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <div key={index} className="border-b border-green-700">
                  <button
                    onClick={() => setOpenFAQ(openFAQ === index ? null : index)}
                    className="flex justify-between items-center w-full py-2"
                  >
                    <span>{faq.question}</span>
                    {openFAQ === index ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </button>
                  {openFAQ === index && (
                    <p className="pb-2 text-green-300">{faq.answer}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={scrollToTop}
        className="fixed bottom-4 right-4 p-3 bg-green-600 rounded-full hover:bg-green-500 transition-colors"
        aria-label="Scroll to top"
      >
        <ArrowUp className="h-6 w-6" />
      </button>
    </footer>
  );
};

export default Footer;