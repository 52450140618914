
export const japanActivities = [
  {
    id: 1,
    title: "Sensō-ji Temple",
    description: "Tokyo's oldest Buddhist temple. Founded in 645 AD, this iconic temple features a massive paper lantern and traditional architecture, surrounded by shopping streets.",
    rating: 4.5,
    duration: "2-3 hours",
    category: "Cultural",
    country: "Japan",
    price: "Free",
    link: "https://www.tripadvisor.com/Attraction_Review-g14134311-d320447-Reviews-Senso_ji_Temple-Asakusa_Taito_Tokyo_Tokyo_Prefecture_Kanto.html",
    location: "2-3-1 Asakusa, Taito City, Tokyo"
  },
  {
    id: 2,
    title: "Shibuya Crossing",
    description: "The world's busiest pedestrian crossing, with up to 3,000 people crossing at once. The perfect spot for experiencing Tokyo's energy and taking photos.",
    rating: 4.7,
    duration: "30 mins",
    category: "Landmarks",
    country: "Japan",
    price: "Free",
    link: "https://www.tripadvisor.com/Attraction_Review-g1066456-d4403399-Reviews-Shibuya_Crossing-Shibuya_Tokyo_Tokyo_Prefecture_Kanto.html",
    location: "2-2-1 Dogenzaka, Shibuya City, Tokyo"
  },
  {
    id: 3,
    title: "Tokyo Skytree",
    description: "The world's tallest tower offers breathtaking views of Tokyo from its observation decks at 350m and 450m high. Features restaurants and shopping areas.",
    rating: 4.6,
    duration: "2 hours",
    category: "Attractions",
    country: "Japan",
    price: "$25",
    link: "https://www.tripadvisor.com/Attraction_Review-g14134337-d1872416-Reviews-Tokyo_Skytree-Oshiage_Sumida_Tokyo_Tokyo_Prefecture_Kanto.html",
    location: "1-1-2 Oshiage, Sumida City, Tokyo"
  },
  {
    id: 4,
    title: "Tsukiji Outer Market",
    description: "Famous for fresh seafood and sushi restaurants. Browse through hundreds of shops selling kitchen tools, seafood, and Japanese groceries.",
    rating: 4.8,
    duration: "3-4 hours",
    category: "Food & Shopping",
    country: "Japan",
    price: "Free (food costs vary)",
    link: "https://www.tripadvisor.com/Attraction_Review-g14129727-d3619250-Reviews-JAPANiCAN_Tsukiji_Outer_Fish_Market_and_Sushi_Workshop-Hamamatsucho_Minato_Toky.html",
    location: "4-16-2 Tsukiji, Chuo City, Tokyo"
  },
  {
    id: 5,
    title: "Meiji Shrine",
    description: "Serene Shinto shrine dedicated to Emperor Meiji and Empress Shoken. Located in a peaceful forest with beautiful wooden torii gates.",
    rating: 4.7,
    duration: "1-2 hours",
    category: "Cultural",
    country: "Japan",
    price: "Free",
    link: "https://www.tripadvisor.com/Attraction_Review-g1066456-d1373780-Reviews-Meiji_Jingu_Shrine-Shibuya_Tokyo_Tokyo_Prefecture_Kanto.html",
    location: "1-1 Yoyogikamizonocho, Shibuya City, Tokyo"
  },
  {
    id: 6,
    title: "TeamLab Planets",
    description: "Digital art museum where visitors wade through knee-deep water while exploring immersive digital art installations.",
   
    rating: 4.9,
    duration: "2-3 hours",
    category: "Art & Museum",
    country: "Japan",
    price: "$30",
    link: "https://www.tripadvisor.com/Attraction_Review-g14134359-d14951238-Reviews-TeamLab_Planets_TOKYO-Toyosu_Koto_Tokyo_Tokyo_Prefecture_Kanto.html",
    location: "6-1-16 Toyosu, Koto City, Tokyo"
  }
];