import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";
import hotel01 from "../../images/hotels/hotel01.webp";
import hotel02 from "../../images/hotels/hotel02.webp";
import hotel03 from "../../images/hotels/hotel03.webp";
import hotel04 from "../../images/hotels/hotel04.webp";
import hotel05 from "../../images/hotels/hotel05.webp";
import hotel06 from "../../images/hotels/hotel06.webp";
const tourCategories = [
  {
    name: " Rambagh Palace",
    image: hotel01,
    link: "https://www.tripadvisor.com/Hotel_Review-g304555-d302157-Reviews-Rambagh_Palace-Jaipur_Jaipur_District_Rajasthan.html",
  },
  {
    name: "Ozen Reserve Bolifushi ",
    image: hotel02,
    link: "https://www.tripadvisor.com/Hotel_Review-g6580337-d21512218-Reviews-Ozen_Reserve_Bolifushi_Luxury_All_Inclusive-Bolifushi_Island.html",
  },
  {
    name: "Hotel Colline de France",
    image: hotel03,
    link: "https://www.tripadvisor.com/Hotel_Review-g303536-d13871137-Reviews-Hotel_Colline_de_France-Gramado_State_of_Rio_Grande_do_Sul.html",
  },
  {
    name: "Shangri-La The Shard, London",
    image: hotel04,
    link: "https://www.tripadvisor.com/Hotel_Review-g186338-d6484754-Reviews-Shangri_La_The_Shard_London-London_England.html",
  },
  {
    name: "OBLU SELECT Lobigili",
    image: hotel05,
    link: "https://www.tripadvisor.com/Hotel_Review-g298571-d23932833-Reviews-OBLU_SELECT_Lobigili-Male.html",
  },
  {
    name: "La Siesta Hoi An Resort & Spa ",
    image: hotel06,
    link: "https://www.tripadvisor.com/Hotel_Review-g298082-d4339781-Reviews-La_Siesta_Hoi_An_Resort_Spa-Hoi_An_Quang_Nam_Province.html",
  },
];
const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === tourCategories.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? tourCategories.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="py-16 px-4 ">
      <div className="w-full mx-auto">
        <h2 className="text-center text-4xl font-bold text-gray-800 mb-12">
          Wonderful Place For You
        </h2>

        <div className="relative">
          <div className="flex overflow-x-hidden">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 20}%)` }}
            >
              {tourCategories.map((category, index) => (
                <div 
                  key={index}
                  className="w-full sm:w-1/3 md:w-1/3 lg:w-1/4 flex-shrink-0 px-2"
                >
                  <div className="bg-white rounded-full overflow-hidden shadow-lg mb-4">
                    <img
                      src={category.image}
                      alt={category.name}
                      className="w-full h-48 object-cover"
                    />
                  </div>
                  <h4 className="text-center text-lg font-semibold text-gray-800">
                    {category.name}
                  </h4>
                  <Link to={category.link} className="text-center text-sm text-blue-500 hover:underline cursor-pointer flex justify-center">
                    See More
                  </Link>
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={prevSlide}
            className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          >
            <ChevronLeft className="w-6 h-6 text-gray-800" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          >
            <ChevronRight className="w-6 h-6 text-gray-800" />
          </button>
        </div>

        <div className="flex justify-center mt-6">
          {tourCategories.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full mx-1 ${
                index === currentIndex ? "bg-green-800" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
