import React from 'react';
import { useParams } from 'react-router-dom';
import HotelSection from '../components/hotel/HotelSection';
import ActivitySection from '../components/activity/ActivitySection';
import { allHotels } from  '../data/hotels';
import { allActivities } from '../data/activities';

const CountryPage = () => {
  const { country } = useParams();
  const countryHotels = allHotels?.filter(
    hotel => hotel?.country?.toLowerCase() === country?.toLowerCase()
  );

  const countryActivities = allActivities?.filter(
    activity => activity?.country?.toLowerCase() === country?.toLowerCase()
  );
  return (
    <div>
      <HotelSection 
        cityName={countryHotels[0]?.city || country}
        hotels={countryHotels}
      />
      <ActivitySection
        cityName={countryActivities[0]?.city || country}
        activities={countryActivities}
      />
    </div>
  );
};

export default CountryPage;


