import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, ArrowUp } from 'lucide-react';
import person01 from '../../images/person/person01.webp';
import person02 from '../../images/person/person02.webp';
import person03 from '../../images/person/person03.webp';
import person04 from '../../images/person/person04.webp';

 import test01 from '../../images/hotels/test01.webp';
 import test02 from '../../images/hotels/19197585.jpg';

const testimonials = [
  {
    name: 'Akiko Tanaka',
    country: 'Japan',
    quote: 'This hotel booking site made my Japan trip extraordinary. The wide range of options from traditional ryokans to modern hotels allowed me to fully experience Japanese culture. The detailed descriptions of local amenities were particularly helpful.',
    rating: 5,
    avatar: person04
  },

  {
    name: 'Isabella Rossi',
    country: 'Italy',
    quote: 'Thanks to this site, I discovered a charming Tuscan agriturismo. The option to filter eco-friendly properties helped me find a place that perfectly matched my environmentally conscious travel style. The virtual tours feature really sold me on the property!',
    rating: 4,
    avatar: person02
  },
  {
    name: 'Olga Petrov',
    country: 'Russia',
    quote: 'The site offered excellent options for winter getaways. I booked a cozy chalet in the mountains with stunning views. The convenient filters for ski-in/ski-out properties and proximity to lifts helped me find exactly what I was looking for!',
    rating: 5,
    avatar: person03
  },
  {
    name: 'Carlos Mendoza',
    country: 'Mexico',
    quote: 'As a digital nomad, I need reliable accommodations with strong Wi-Fi. This site specific filter for work-friendly stays and verified internet speeds was a game-changer. I found a perfect long-term rental in Playa del Carmen that became my home office paradise.',
    rating: 5,
    avatar: person01
  }
];
const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="relative py-16 px-4 ">
      <div className="max-w-6xl mx-auto">
        {/* <h2 className="text-3xl font-cursive text-gray-600 mb-2">Testimonials</h2> */}
        <h3 className="text-4xl font-bold text-gray-800 mb-8 text-center">What Client Say About us</h3>
        
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
            <div className="relative">
              <img src={test01} alt="Beach sunset" className="w-full h-auto rounded-tl-[100px] rounded-br-[100px]" />
              <img src={test02} alt="Scenic view" className="absolute bottom-0 right-0 w-1/3 rounded-tl-[50px]" />
            </div>
          </div>
          
          <div className="w-full md:w-1/2 pl-0 md:pl-8">
            <blockquote className="text-lg text-gray-600 mb-4">
              "{testimonials[currentIndex].quote}"
            </blockquote>
            <div className="flex items-center mb-4">
              <div className="mr-4">
                <p className="font-bold text-gray-800">{testimonials[currentIndex].name}</p>
                <p className="text-gray-600">{testimonials[currentIndex].role}</p>
              </div>
              <div className="flex">
                {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                  <span key={i} className="text-yellow-400">★</span>
                ))}
              </div>
            </div>
            <div className="flex -space-x-2">
              {testimonials.map((testimonial, index) => (
                <img
                  key={index}
                  src={testimonial.avatar}
                  alt={`Avatar ${index + 1}`}
                  className={`w-10 h-10 rounded-full border-2 border-white ${index === currentIndex ? 'ring-2 ring-green-500' : ''}`}
                />
              ))}
            </div>
          </div>
        </div>
        
        <div className="absolute bottom-4 right-4 flex space-x-2">
          <button onClick={prevSlide} className="p-2 bg-white rounded-full shadow-md">
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <button onClick={nextSlide} className="p-2 bg-white rounded-full shadow-md">
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>
        </div>
      </div>
      
      <button className="fixed bottom-4 right-4 p-3 bg-green-500 text-white rounded-full shadow-lg">
        <ArrowUp className="w-6 h-6" />
      </button>
    </section>
  );
};

export default Testimonials;