import React from "react";
import {
  Plane,
  Hotel,
  Car,
  Compass,
  Shield,
  HeadphonesIcon,
  Clock,
  CreditCard,
  Check,
  Link,
} from "lucide-react";

const Services = () => {
  const mainServices = [
    {
      icon: <Plane className="w-12 h-12 text- green-500 " />,
      title: "Flight Booking",
      description:
        "Get the best deals on flights worldwide with our advanced booking system and price alerts.",
      features: [
        "Real-time flight tracking",
        "Price match guarantee",
        "Free cancellation options",
        "Exclusive airline partnerships",
      ],
    },
    {
      icon: <Hotel className="w-12 h-12 text- green-500" />,
      title: "Hotel Reservations",
      description:
        "Book luxury accommodations at competitive prices with our extensive network of partners.",
      features: [
        "Best price guarantee",
        "Verified hotel reviews",
        "Flexible booking options",
        "Special member discounts",
      ],
    },
    {
      icon: <Car className="w-12 h-12 text- green-500" />,
      title: "Transportation",
      description:
        "From airport transfers to car rentals, we ensure seamless travel experiences.",
      features: [
        "Airport pickup & drop-off",
        "Luxury vehicle options",
        "24/7 driver support",
        "Global coverage",
      ],
    },
    {
      icon: <Compass className="w-12 h-12 text- green-500" />,
      title: "Travel Packages",
      description:
        "Customized travel packages including flights, hotels, and activities at the best prices.",
      features: [
        "Personalized itineraries",
        "Group travel options",
        "Local experiences",
        "Guided tours available",
      ],
    },
  ];

  const additionalFeatures = [
    {
      icon: <Shield className="w-6 h-6 text-green-500" />,
      title: "Secure Booking",
      description:
        "Your payments and personal information are protected with advanced encryption.",
    },
    {
      icon: <HeadphonesIcon className="w-6 h-6 text-green-500" />,
      title: "24/7 Support",
      description:
        "Our travel experts are available round the clock to assist you.",
    },
    {
      icon: <Clock className="w-6 h-6 text-green-500" />,
      title: "Fast Confirmation",
      description: "Instant confirmation and updates for all your bookings.",
    },
    {
      icon: <CreditCard className="w-6 h-6 text-green-500" />,
      title: "Flexible Payment",
      description: "Multiple payment options and installment plans available.",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-20 px-4 sm:px-6 lg:px-8">
      {/* Hero Section */}
      <div className="text-center max-w-4xl mx-auto mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Our Premium Travel Services
        </h1>
        <p className="text-lg text-gray-600">
          Experience seamless travel planning with our comprehensive range of
          services designed to make your journey memorable.
        </p>
      </div>

      {/* Main Services */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        {mainServices.map((service, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center mb-6">
              {service.icon}
              <h3 className="text-2xl font-semibold ml-4 text-gray-900">
                {service.title}
              </h3>
            </div>
            <p className="text-gray-600 mb-6">{service.description}</p>
            <ul className="space-y-3">
              {service.features.map((feature, idx) => (
                <li key={idx} className="flex items-center text-gray-700">
                  <Check className="w-5 h-5 text-green-500 mr-3" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Additional Features */}
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
          Why Choose Our Services?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {additionalFeatures.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-lg p-6 text-center hover:shadow-md transition-shadow duration-300"
            >
              <div className="flex justify-center mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2 text-gray-900">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="max-w-4xl mx-auto text-center mt-16 bg- green-50 rounded-lg p-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Ready to Start Your Journey?
        </h2>
        <p className="text-gray-600 mb-6">
          Contact our travel experts to plan your perfect trip today.
        </p>
        <button
          onClick={() => window.open("https://www.tripadvisor.com/")}
          className="bg-green-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-green-700 transition-colors duration-300"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Services;
