// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Layout from "./pages/Layout/Layout";
import Contact from "./pages/Contact/Contact";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import About from "./pages/Abouts/About";
import Footer from './components/footer/Footer'
import CountryPage from './pages/[country]';
import Services from "./pages/Services/Service";
import FAQPage from "./pages/Faq/Faq";
import ScrollToTop from "./components/scrollTop/ScrollTop";


function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/home" element={<Layout />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/:country" element={<CountryPage />} />
        <Route path="/services" element={<Services/>} />
        <Route path="/faq" element={<FAQPage/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
