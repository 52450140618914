export const mexicoActivities = [
    {
      id: 1,
      title: "Chichen Itza",
      description: "One of the New Seven Wonders of the World, this ancient Mayan city features the iconic El Castillo pyramid, sacred cenotes, and remarkable astronomical structures.",
      rating: 4.8,
      duration: "4-6 hours",
      category: "Archaeological",
      country: "Mexico",
      price: "$30",
      link: "https://www.tripadvisor.com/Attraction_Review-g150808-d153407-Reviews-Chichen_Itza-Chichen_Itza_Yucatan_Peninsula.html",
      location: "Yucatan Peninsula, Tinum Municipality"
    },
    {
      id: 2,
      title: "Xcaret Park",
      description: "Eco-archaeological park featuring Mayan ruins, cultural shows, snorkeling in underground rivers, and Mexican folklore performances.",
      rating: 4.7,
      duration: "Full day",
      category: "Entertainment",
      country: "Mexico",
      price: "$120",
      link: "https://www.tripadvisor.com/Attraction_Review-g150812-d152777-Reviews-Xcaret-Playa_del_Carmen_Yucatan_Peninsula.html",
      location: "Carretera Chetúmal-Puerto Juárez Kilómetro 282, Playa del Carmen"
    },
    {
      id: 3,
      title: "Frida Kahlo Museum",
      description: "The Blue House where the famous artist lived, featuring her artwork, personal items, and Mexican folk art collection.",
      rating: 4.6,
      duration: "2-3 hours",
      category: "Cultural",
      country: "Mexico",
      price: "$15",
      link: "https://www.tripadvisor.com/Attraction_Review-g150812-d12693229-Reviews-Museo_Frida_Kahlo-Playa_del_Carmen_Yucatan_Peninsula.html",
      location: "Londres 247, Del Carmen, Coyoacán, Mexico City"
    },
    {
      id: 4,
      title: "Tulum Archaeological Site",
      description: "Stunning Mayan ruins perched on cliffs overlooking the Caribbean Sea, featuring well-preserved temples and beautiful beaches.",
      rating: 4.7,
      duration: "2-3 hours",
      category: "Archaeological",
      country: "Mexico",
      price: "$25",
      link: "https://www.tripadvisor.com/Attraction_Review-g150813-d153186-Reviews-Tulum_Archaeological_Site-Tulum_Yucatan_Peninsula.html",
      location: "Carretera Federal 307, Tulum"
    },
    {
      id: 5,
      title: "Cenote Swimming Experience",
      description: "Explore and swim in natural limestone sinkholes with crystal-clear water, perfect for swimming and snorkeling.",
      rating: 4.9,
      duration: "3-4 hours",
      category: "Nature",
      country: "Mexico",
      price: "$40",
      link: "https://www.tripadvisor.com/AttractionProductReview-g150812-d25415066-Exotik_half_a_day_tour_Cenote_Cave_and_Swimming_with_Sea_Turtles-Playa_del_Carmen_.html",
      location: "Various locations in Yucatan Peninsula"
    },
    {
      id: 6,
      title: "Teotihuacan Pyramids",
      description: "Ancient Mesoamerican ruins featuring the massive Pyramids of the Sun and Moon, with option to climb for panoramic views.",
      rating: 4.8,
      duration: "4-5 hours",
      category: "Archaeological",
      country: "Mexico",
      price: "$20",
      link: "https://www.tripadvisor.com/Attraction_Review-g150812-d15274859-Reviews-Estacion_Mexico_Free_Tours-Playa_del_Carmen_Yucatan_Peninsula.html",
      location: "San Juan Teotihuacán, State of Mexico"
    }

  ];